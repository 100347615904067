import { useState, useEffect } from "react";
import "./session.css";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import GlobalAlert from "../Global/GlobalAlert";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

const AdminGrid = () => {
  const [rows, setRows] = useState([]);
  const token = localStorage.getItem("token");
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: true,
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const openDeleteModal = (e = "") => {
    console.log(e);
    setUserEmail(e.row.email);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setUserEmail("");
    setDeleteModal(false);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (token) {
      getUsers();
    } else {
      navigate("/administrator");
    }
  }, []);

  const getUsers = () => {
    axios
      .post("https://juradvice.be/api/admin/all_users.php", "")
      .then((response) => {
        if (response.status === 200) {
          if (response.data.data && response.data.data.length > 0) {
            let responseData = response.data.data;
            let arr = responseData.map((val, index) => ({
              ...val,
              id: index,
            }));
            setRows(arr);
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const columns = [
    {
      field: "fullname",
      headerName: "Name",
      width: 200,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 700,
      editable: false,
    },
    {
      field: "subscription_status",
      headerName: "Status",
      width: 110,
      editable: false,
      renderCell: (params) => {
        const isVip = params.value === "VIP";
        return (
          <div className={`status-cell vipStatus ${isVip ? "vip-status" : ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "deleteUser",
      headerName: "Action",
      width: 110,
      editable: false,
      renderCell: (e) => {
        return (
          <button className="deletebutton actionButtons">
            <DeleteIcon onClick={() => openDeleteModal(e)} />
          </button>
        );
      },
    },
  ];
  /* Delete User Api */
  const deleteUser = () => {
    let requestData = {
      email: userEmail,
    };

    axios
      .post("https://juradvice.be/api/admin/delete_user.php", requestData)
      .then((response) => {
        if (response.status === 200) {
          setAlertMessages({
            message: "User delete successfully",
            status: "error",
            flag: true,
          });
          getUsers();
          closeDeleteModal();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <GlobalAlert
        setAlertMessages={setAlertMessages}
        alertMessages={alertMessages}
      />

      <Dialog
        open={deleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete User?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do You Want To Delete This User ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>No</Button>
          <Button onClick={deleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <div className="row m-0 mt-3">
        <div className="col-12">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid rows={rows} columns={columns} pageSize={10} />
          </Box>
        </div>
      </div>
    </>
  );
};

export default AdminGrid;
