import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import PrivacyPolicyFr from "./PrivacyPolicyFr";
import PrivacyPolicyNl from "./PrivacyPolicyNl";

function PrivacyPolicy() {
  const lang = localStorage.getItem("lang");
  const privacy = () => {
    return (
      <>
      <TopNav disableBackground={true} />
      <div className="policyContent container">
    <h1>Privacy and Cookie Statement</h1>
    <p>
        This "Privacy Policy" regulates the processing of personal data by the data controller: Juradvice (hereinafter referred to
        as "Controller," "We," or "Juradvice"). Please read this Privacy Policy carefully as it contains essential information
        about how your personal data is processed and which cookies are used. By providing your personal data on the website and
        by using the email addresses listed on this website to provide personal data, you declare that you have read this Privacy
        Policy and expressly agree to the processing itself.
    </p>

    <h2>Article 1 – General</h2>
    <p>
        Juradvice follows the "Law of 8 December 1992 on the protection of privacy with regard to the processing of personal
        data," as amended by the Law of 11 December 1998, and the anti-spam provisions of Book XII of the Economic Law Code
        regarding the "right of the electronic economy." Juradvice also complies with the European Regulation 2016/679 of 27
        April 2016 on the protection of personal data. The data controller for the processing of your personal data is Juradvice,
        with a declaration to the Commission for the Protection of Privacy.
    </p>

    <h2>Article 2 – Personal Data</h2>
    <p>
        2.1. Personal data you provide to us: Your IP address, browsing behavior, origin, search terms, profile name, password,
        email address, login details, profile picture, contact details, personal data, and certain data are collected via cookies
        (see Article 9 for cookie policy).
    </p>

    <p>
        2.2. Method of data collection: This includes the use of cookies, registration or use of the platform, requesting further
        information after contact with Juradvice, filling out forms for issue discussion, and sharing data with other users on
        our sites.
    </p>

    <p>
        2.3. Cookies: The website uses cookies for data collection. See Article 9 for the cookie policy.
    </p>

    <h2>Article 3 – Purposes of Processing</h2>
    <p>
        3.1. General purposes: Juradvice will use personal data exclusively for improving the forum, managing your account,
        direct marketing, answering questions, and verifying data. See Article 9 for the cookie policy.
    </p>

    <p>
        3.2. Direct marketing: Data is used for direct marketing with your explicit consent ("opt-in"). You can withdraw this
        consent at any time.
    </p>

    <p>
        3.3. Transfer to third parties: In the event of a reorganization or transfer of Juradvice's activities, data may be
        transferred to new entities or third parties. Juradvice does not sell, rent, or commercially share your personal data
        without your consent.
    </p>

    <p>
        3.4. Legal requirements: In rare cases, Juradvice may disclose personal data to comply with court orders or mandatory
        legal or regulatory requirements.
    </p>

    <h2>Article 4 – Duration of Processing</h2>
    <p>
        Personal data is kept and processed as long as necessary for the processing purposes and the contractual relationship
        between Juradvice and you.
    </p>

    <h2>Article 5 – Your Rights</h2>
    <p>
        5.1. Right of access and inspection: You have the right to freely access your personal data and its use by Juradvice.
    </p>

    <p>
        5.2. Right of correction, deletion, and limitation: You have the right to have personal data corrected, supplemented, or
        deleted, and can request the limitation of its processing.
    </p>

    <p>
        5.3. Right of objection: You have the right to object to the processing of your personal data and its use for direct
        marketing without giving reasons.
    </p>

    <p>
        5.4. Right of withdrawal of consent: For processing based on consent, you can withdraw the consent at any time.
    </p>

    <p>
        5.5. Exercise of your rights: Rights can be exercised by contacting info@Juradvice.be.
    </p>

    <p>
        5.6. More information: For more information on privacy rights and the processing of personal data, you can contact the
        Belgian Privacy Commission.
    </p>

    <h2>Article 6 – Security and Confidentiality</h2>
    <p>
        6.1. Juradvice has implemented security measures to protect personal data from destruction, loss, falsification, alteration,
        unauthorized access, or disclosure to third parties.
    </p>

    <p>
        6.2. Juradvice is not liable for damages resulting from incorrect or unlawful use of personal data by third parties.
    </p>

    <p>
        6.3. You must comply with security regulations, such as preventing unauthorized access to your login and code. You are
        responsible for using the website from your computer, IP address, and identification data, and for its confidentiality.
    </p>

    <h2>Article 7 – Access by Third Parties</h2>
    <p>
        Juradvice grants access to personal data to employees, with guarantees of protection level according to this Privacy Policy.
    </p>

    <h2>Article 8 – Cookies</h2>
    <p>
        8.1. What are cookies: A "cookie" is a small file sent by Juradvice's server and placed on your computer's hard drive.
        Information on these cookies is readable during the visit to the website.
    </p>

    <p>
        8.2. Why do we use cookies: Cookies and similar technologies distinguish usage preferences, improve the user experience,
        and optimize the website. Consent is requested on the first visit, and cookie settings can be changed.
    </p>

    <p>
        8.3. Types of cookies: Functional or necessary cookies are used without prior consent. Other cookies, such as performance and
        advertising cookies, require your consent. Juradvice reserves the right to place a script to detect ad blockers.
    </p>

    <h2>Controller for the Management of this Website:</h2>
    <p>
        Juradvice
        <br />
        Email: <a href="mailto:info@juradvice.be"><u>info@juradvice.be</u></a>
    </p>
</div>

      <Footer />
    </>
    )
  }

  const changePrivacy = () => {
    if(lang == "en") {
      return privacy();
    }
    else if(lang === "fr") 
    {
      return <PrivacyPolicyFr />
    }
    else if(lang === "nl")
    {
      return <PrivacyPolicyNl />
    }
    else {
      return privacy();
    }
  }
  return (
    <>
     {changePrivacy()}
    </>
  );
}
export default PrivacyPolicy;
