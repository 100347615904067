import { Card, Checkbox, Grid, Modal, TextField, Typography, FormControl, MenuItem, InputLabel, Select, FormControlLabel, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import "./session.css";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Order from './Order.png'
import Logo from './Logo.png'
import { CheckBox } from "@mui/icons-material";
import axios from "axios";
  
  const AdminLogin = () => {
  
    const theme = createTheme({
      components: {
        MuiOutlinedInput: {
          styleOverrides: {
            root: {
              borderRadius: "20px", // Set your desired border radius here
            },
            input: {
              borderRadius: "20px !important",
            },
          },
        },
      },
    });
  
  const [currentTab, setCurrentTab] = useState(false);
  const navigate = useNavigate();
  const changeTab = (event) => {
    if (event === "SignUp") {
      setCurrentTab(true);
    }
  };
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [showPassword, setShowPassword] = useState(false);
    const [loginFormData,setLoginFormData] = useState({
      loginEmail:'',
      loginPassword:'',
     
    });
    const [alertMessages,setAlertMessages] = useState({
      message:'',
      status:'',
      flag:false,
    })
    const [btnDisable,setBtnDisable] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
 
  const login = () => {
    let payload = {
      email:loginFormData.loginEmail,
      password:loginFormData.loginPassword
    }

     const validation = loginValidations(payload);

    if(validation && payload.email.trim() === 'info@juradvice.be') 
    {
          
    axios.post('https://juradvice.be/api/account/user_login.php',payload).then((response) => {
      if(response.status === 200)
      {
        if(response.data.status !== 'error')
        {
        let token = response.data.token;
        let email = response.data.user_email;
        localStorage.setItem('token',token);
        localStorage.setItem('email',email);
        
              navigate('/users')
        }
        else {
          setAlertMessages(({
            message:response.data.message,
            status:'error',
            flag:true
          }));

        } 
        
        
      }
    })
  }
  };


  /* Login Validations */

  const loginValidations = (payload) => {
    if(payload.email.trim() === '')
    {
      setAlertMessages({
        message:'Please enter your email address',
        status:'error',
        flag:true
      })
      return false
    }
    if(payload.password.trim() === '')
    {
      setAlertMessages({
        message:'Please enter your password',
        status:'error',
        flag:true
      })
      return false
    }
    return true
}
  
    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  /* on Blur */
const onBlur = (event) => {
  const {value} = event.target

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if (!emailRegex.test(value)) {
     setAlertMessages({
      message:'Please enter a valid email address',
      status:'error',
      flag:true
     })
      setBtnDisable(true);
  }
  else {
    setBtnDisable(false)
  }
  }
  const onChange = (event,fieldName = '') => {
    const {name, value,type } = event.target;
   
  
    if(fieldName === 'loginPassword')
    {
      setLoginFormData((prevState) => ({
        ...prevState,
        [fieldName]:value
      }));
      return;
    }
    else {
      setLoginFormData((prevState) => ({
        ...prevState,
        [name]:value
      }));
    }
  }

  const submit = () => {
    login();
  }
    return (
      <>
        <div className="d-flex align-items-center" style={{ height: "100vh" }}>
        {windowWidth <= 700 ? "" : <div className="w-50" >
            <img className="LoginImg" src={Order} /></div>}
            <div className={windowWidth <= 700 ? "w-100" : "w-50"} style={{ height: "85vh" }}>
            <div
              className="d-flex flex-column align-items-center"
              style={{
                gap: "10px",
              }}
            >
              <img className={windowWidth <= 1024?"w-50":"w-25"} style={{ height: "130px",objectFit: "cover", }} src={Logo} />
              <div className="tabBackground">
                <div
                  className={ "button3" }
                  onClick={() => changeTab("Login")}
                >
                  Admin Login
                </div>
              </div>
              <ThemeProvider theme={theme}>
              <div className={windowWidth <= 700 ? "w-75 d-flex flex-column" : "w-50 d-flex flex-column mt-5"}>
                  <label>Email</label>
                  <TextField
                   placeholder="Email"
                    autoComplete="off"
                    className="mt-1 mb-3"
                    size="small"
                    onChange={onChange}
                    value={loginFormData.loginEmail}
                    name="loginEmail"
                    onBlur={onBlur}
                    InputProps={{
                      style: { borderRadius: "30px", border: "none" }, // Remove the border
                    }}
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                  />
  
                <label>Password</label>
                  <FormControl fullWidth className="mb-3" variant="outlined" size="small">
                    <OutlinedInput
                      id="outlined-adornment-password"
                      placeholder="Password"
                      onChange={onChange}
                      name="loginPassword"
                      value={loginFormData.loginPassword}
                      type={showPassword ? 'text' : 'password'}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility" 
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      }
                      />
                  </FormControl>
                  <button className="loginBtn mt-3" onClick={submit}>Submit</button>
                      </div>

              </ThemeProvider>
            </div>
          </div>
        </div>
      </>
    );
  };
  
  export default AdminLogin;
  