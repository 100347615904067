import lawandorder from "./lawandorder.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import OriginalLogo from "./OriginalLogo.svg";
import { MatxMenu } from "app/components";
import { Avatar, Hidden, Icon, TextField } from "@mui/material";
import { Span } from "app/components/Typography";
import { Box, styled } from "@mui/system";
import "./session.css";
import { useRef, useState } from "react";
import whitelogo from "./whiteLogocrop.png";
import { logout, navLang } from "../Global/globalFunction";
import { Button } from "bootstrap";
import LegalQuestions from "./LegalQuestions";
import SearchBox from "./SearchBox";

const TopNav = (props) => {
  const [showNavbar, setShowNavbar] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };
  const UserMenu = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    borderRadius: 24,
    padding: 4,
    marginTop: "-7px",
    "& span": { margin: "0 8px" },
  }));

  const login = () => {
    if (token) {
      logout();
    }
    navigate("/login");
  };

  const [showMobileSubMenu, setShowMobileSubMenu] = useState(false);

  const toggleMobileSubMenu = () => {
    setShowMobileSubMenu(!showMobileSubMenu);
  };

  return (
    <>
      <div>
        {props.disableBackground === true ? (
          ""
        ) : (
          <img className="StickyImage" src={lawandorder} />
        )}
        <div className="mainMenuDiv">
          <nav
            className="navbar"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Link className="headerSec1" to={"/home"}>
                <img className="headerLogo" src={whitelogo} alt="logo" />
              </Link>
              <ul className="headerSec2" id="mainMenu">
                <li>
                  <Link to={"/home"}>
                    {lang ? navLang(lang, "Home") : "Home"}
                  </Link>
                </li>
                <li>
                  <Link to={"/who-we-are"}>
                    {lang ? navLang(lang, "Who Are We") : "Who Are We"}
                  </Link>
                </li>
                <li>
                  <Link to={"/our-purpose"}>
                    {lang ? navLang(lang, "Our Purpose") : "Our Purpose"}
                  </Link>
                </li>
              </ul>
              <ul className="headerSec3" id="memberMenu">
                <li className="membersAreaLink">
                  <img
                    width="32"
                    height="32"
                    src="https://img.icons8.com/windows/32/000000/user.png"
                    alt="user"
                  />{" "}
                  {lang ? navLang(lang, "Members Area") : "Members Area"}
                  <ul className="MemberSubmenu">
                    <li>
                      <Link to={token ? "/my-profile" : "/login"}>
                        {lang ? navLang(lang, "My Profile") : "My Profile"}
                      </Link>
                    </li>
                    <li>
                      <Link id="submitQuestionNavItem" to={token ? "/submit-question" : "/login"}>
                        {lang
                          ? navLang(lang, "Submit Question")
                          : "Submit Question"}
                      </Link>
                    </li>
                    <li id="loginNavItem">
                      <Link to={token ? "/my-questions" : "/login"}>
                        {lang ? navLang(lang, "My Questions") : "My Questions"}
                      </Link>
                    </li>
                    <li>
                      <button style={{ color: "#ffffff" }} onClick={login}>
                      {token ? (lang ? navLang(lang, "Logout") : "Logout")  : (lang ? navLang(lang, "Login") : "Login")}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
        </div>
       
        <div className="mainMenuMobileOnly">
          <div className="mobileLeftCol">
            <Link className="headerSec1" to={"/home"}>
              <img className="headerLogo" src={whitelogo} alt="logo" />
            </Link>
          </div>
          <div className="mobileRightCol">
            <img
              onClick={toggleMobileSubMenu}
              className="hamburgerMobile"
              width="35"
              height="35"
              src="https://img.icons8.com/ios/50/menu--v1.png"
              alt="menu--v1"
            />
            <div className={`mobileSubMenu ${showMobileSubMenu ? "show" : ""}`}>
              <Link to={"/home"}>{lang ? navLang(lang, "Home") : "Home"}</Link>
              <Link to={"/who-we-are"}>
                {lang ? navLang(lang, "Who Are We") : "Who Are We"}
              </Link>
              <Link to={"/our-purpose"}>
                {lang ? navLang(lang, "Our Purpose") : "Our Purpose"}
              </Link>
              <Link to={token ? "/my-profile" : "/login"}>
                {lang ? navLang(lang, "My Profile") : "My Profile"}
              </Link>
              <Link to={token ? "/submit-question" : "/login"}>
                {lang ? navLang(lang, "Submit Question") : "Submit Question"}
              </Link>
              <Link to={token ? "/my-questions" : "/login"}>
                {lang ? navLang(lang, "My Questions") : "My Questions"}
              </Link>
              <button style={{ color: "#ffffff" }} onClick={login}>
                {token ? "Logout"  : "Login"}
              </button>
            </div>
          </div>
        </div>
        <SearchBox />
      </div>
    </>
  );
};

export default TopNav;
