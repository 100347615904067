import { Provider } from 'react-redux';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import { SettingsProvider } from './contexts/SettingsContext';
import { Store } from './redux/Store';
import routes from './routes';
import Analytics from './views/dashboard/dashboard';

const App = () => {
  const content = useRoutes(routes);

  return (
    <Provider store={Store} >
      <SettingsProvider>
        <MatxTheme>
        {/* <Analytics /> */}
          {content}
        </MatxTheme>
      </SettingsProvider>
    </Provider>
  );
};

export default App;
