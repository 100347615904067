import { useState, useEffect } from "react";
import "./session.css";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Modal, TextField, Typography, TextArea } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import HdrAutoIcon from "@mui/icons-material/HdrAuto";
import axios from "axios";
import GlobalAlert from "../Global/GlobalAlert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";

export default function Questions() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [question, setQuestions] = useState({
    en: {
      id: "",
      question: "",
      answer: "",
    },
    fr: {
      id: "",
      question: "",
      answer: "",
    },
    nl: {
      id: "",
      question: "",
      answer: "",
    },
  });
  const [isEdit, setIsEdit] = useState(false);
  const [rows, setRows] = useState([]);
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: true,
  });
  const [questionId, setQuestionId] = useState("");
  const headerstyle = {
    color: "#ffffff",
    background: "#224589",
    height: "50px",
  };
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const initialState = { en: {
    id: "",
    question: "",
    answer: "",
  },
  fr: {
    id: "",
    question: "",
    answer: "",
  },
  nl: {
    id: "",
    question: "",
    answer: "",
  },}
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleOpen = (e = "") => {
    console.log("e", e);
    setOpen(true);
    
    if (e !== "") {
      questionDetails(e.id);
      setQuestionId(e.id);
      setIsEdit(true);
    }
    else {
      setIsEdit(false);
    }
  };

  const openDeleteModal = (e = "") => {
    setQuestionId(e.id);
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setQuestionId("");
    setDeleteModal(false);
  };

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    setIsEdit(false);
    setQuestionId("");
  };

  /* Get Question On Id */
  const questionDetails = (question_id) => {
    let request = { question_id: question_id };
    axios
      .post("https://juradvice.be/api/admin/all_questions_detail.php", request)
      .then((response) => {
        if (response.status === 200) {
          setQuestions({
            en: { ...response.data.en_question },
            fr: { ...response.data.fr_question },
            nl: { ...response.data.nl_question },
          });

          setIsEdit(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setQuestions({
          en: {
            id: "",
            question: "",
            answer: "",
          },
          fr: {
            id: "",
            question: "",
            answer: "",
          },
          nl: {
            id: "",
            question: "",
            answer: "",
          },
        });
      });
  };

  /* Edit Question Api */
  const editQuestion = () => {
    let request = {
      en: {
        id: questionId,
        question: question.en.question,
        answer: question.en.answer,
      },
      nl: {
        id: questionId,
        question: question.nl.question,
        answer: question.nl.answer,
      },
      fr: {
        id: questionId,
        question: question.fr.question,
        answer: question.fr.answer,
      },
    };

    axios
      .post("https://juradvice.be/api/admin/edit_questions.php", request)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status !== "error") {
            console.log(response.data);
            setAlertMessages({
              message: response.data.message,
              status: "success",
              flag: true,
            });
            handleClose();
            getQuestions();
          } else {
            setAlertMessages({
              message: response.data.message,
              status: "error",
              flag: true,
            });
          }
        }
      });
  };

  /* Delete Question Api */
  const deleteQuestion = () => {
    let requestData = {
      question_id: questionId,
    };

    axios
      .post("https://juradvice.be/api/admin/delete_question.php", requestData)
      .then((response) => {
        if (response.status === 200) {
          setAlertMessages({
            message: "Question delete successfully",
            status: "error",
            flag: true,
          });
          closeDeleteModal();
          setQuestions(initialState);
          getQuestions();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const columns = [
    {
      field: "question",
      headerName: "Question",
      width: window.innerWidth < 1024 ? 500 : 1000,
      editable: false,
    },
    {
      field: "Action",
      headerName: "Action",
      editable: false,
      description: "This column has a value getter and is not sortable.",
      className: "ActionField",
      renderCell: (e) => {
        return (
          <>
            <button
              onClick={() => handleOpen(e)}
              className="gridbutton actionButtons"
            >
              <EditIcon />
            </button>
            <button className="deletebutton actionButtons ms-2">
              <DeleteIcon onClick={() => openDeleteModal(e)} />
            </button>
          </>
        );
      },

      sortable: false,
      width: 200,
    },
  ];

  // const rows = [
  //   { id: 1, Email: "Amin@gmail.com", question: "Amin", Status: "Free" },
  //   { id: 2, Email: "Amin@gmail.com", question: "Amin", Status: "Free" },
  //   { id: 3, Email: "Amin@gmail.com", question: "Amin", Status: "Free" },
  //   { id: 4, Email: "Amin@gmail.com", question: "Amin", Status: "Free" },
  //   { id: 5, Email: "Amin@gmail.com", question: "Amin", Status: "Free" },
  //   { id: 6, Email: "Amin@gmail.com", question: "Amin", Status: "VIP" },
  //   { id: 7, Email: "Amin@gmail.com", question: "Amin", Status: "VIP" },
  //   { id: 8, Email: "Amin@gmail.com", question: "Amin", Status: "VIP" },
  //   { id: 9, Email: "Amin@gmail.com", question: "Amin", Status: "VIP" },
  // ];

  let className2;

  if (windowWidth <= 400) {
    className2 = "mobilestyle";
  } else if (windowWidth <= 900) {
    className2 = "tabletstyle";
  } else {
    className2 = "questionstyle";
  }
  const responsiveStyle =
    windowWidth <= 400
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "5%",
          overflow: "scroll",
          top: "90px",
        }
      : windowWidth <= 900
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "25%",
          overflow: "scroll",
          top: "10px",
        }
      : {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 800,
          boxShadow: " 24",
          borderRadius: "10px",
          padding: "0px",
        };

  useEffect(() => {
    if (token) {
      getQuestions();
    } else {
      navigate("/administrator");
    }
  }, []);

  const addQuestion = () => {
    let reqData = {
      en: {
        question: question.en.question,
        answer: question.en.answer,
      },
      fr: {
        question: question.fr.question,
        answer: question.fr.answer,
      },
      nl: {
        question: question.nl.question,
        answer: question.nl.answer,
      },
    };

    axios
      .post("https://juradvice.be/api/admin/create_questions.php", reqData)
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status !== "error") {
            console.log("response", response.data);
            setAlertMessages({
              message: response.data.message,
              status: "success",
              flag: true,
            });
            window.location.reload();
            getQuestions();
            handleClose();
          } else {
            setAlertMessages({
              message: response.data.message,
              status: "error",
              flag: true,
            });
          }
        }
      });
  };

  const getQuestions = () => {
    let token = localStorage.getItem("token");
    axios
      .post("https://juradvice.be/api/admin/all_en_questions.php", "")
      .then((response) => {
        if (response.status === 200) {
          setRows(response.data.data);
        }
      })
      .catch((error) => {
        setAlertMessages({
          message: "",
          status: "error",
          flag: true,
        });
      });
  };

  /* On Change */
  const onChange = (event, language) => {
    const { name, value } = event.target;
    if (language === "en") {
      setQuestions((prevState) => ({
        ...prevState,
        en: {
          ...question.en,
          [name]: value,
        },
      }));
    } else if (language === "fr") {
      setQuestions((prevState) => ({
        ...prevState,
        fr: {
          ...question.fr,
          [name]: value,
        },
      }));
    } else {
      setQuestions((prevState) => ({
        ...prevState,
        nl: {
          ...question.nl,
          [name]: value,
        },
      }));
    }
  };

  /* on Blur */
  const onBlur = (event) => {};

  const saveQuestion = () => {
    if (isEdit) {
      editQuestion();
    } else {
      addQuestion();
    }
    setIsEdit(false);
    setQuestionId("");
    setQuestions(initialState);
  };

  return (
    <>
      <GlobalAlert
        setAlertMessages={setAlertMessages}
        alertMessages={alertMessages}
      />

      <Dialog
        open={deleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Question?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do You Want To Delete This Question ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>No</Button>
          <Button onClick={deleteQuestion} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={responsiveStyle} className={className2} id="questionPopup">
          <Typography
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              paddingLeft: "8px",
              paddingTop: "7px",
            }}
            sx={headerstyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <div>
              <h4>Questions</h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-30px",
              }}
            >
              <IconButton onClick={() => handleClose()} aria-label="Close">
                <CloseIcon
                  style={{ marginTop: "-10px", color: "#ffffff" }}
                  className="mx-2"
                />
              </IconButton>
            </div>
          </Typography>
          <div className="m-4">
            <div className="row">
              <div className="col-lg-3">
                <h4>English</h4>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <SearchIcon className="searchBackground" fontSize="small" />
                <TextField
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  multiline
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "en")}
                  onBlur={onBlur}
                  name="question"
                  value={question.en.question}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <HdrAutoIcon className="AnswerBackground" fontSize="small" />
                <TextField
                  multiline
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "en")}
                  onBlur={onBlur}
                  name="answer"
                  value={question.en.answer}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <h4>French</h4>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <SearchIcon className="searchBackground" fontSize="small" />
                <TextField
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  multiline
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "fr")}
                  onBlur={onBlur}
                  name="question"
                  value={question.fr.question}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <HdrAutoIcon className="AnswerBackground" fontSize="small" />
                <TextField
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  multiline
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "fr")}
                  onBlur={onBlur}
                  name="answer"
                  value={question.fr.answer}
                />{" "}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3">
                <h4>Dutch</h4>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <SearchIcon className="searchBackground" fontSize="small" />
                <TextField
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  multiline
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "nl")}
                  onBlur={onBlur}
                  name="question"
                  value={question.nl.question}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12 d-flex popupField">
                <HdrAutoIcon className="AnswerBackground" fontSize="small" />
                <TextField
                  className="col-lg-12 pb-3 mt-3 d-flex justify-content-end"
                  size="small"
                  multiline
                  // style={{padding: "10px", borderRadius: "10px",border: "1px solid #26478E"}}
                  fullWidth
                  label=""
                  variant="outlined"
                  onChange={(event) => onChange(event, "nl")}
                  onBlur={onBlur}
                  name="answer"
                  value={question.nl.answer}
                />
              </div>
            </div>

            <div
              style={{
                display: "contents",
              }}
              className="row mt-3"
            >
              <div
                style={
                  windowWidth >= 1024
                    ? {
                        display: "contents",
                        position: "sticky",
                        float: "right",
                      }
                    : {
                        display: "contents",
                        position: "sticky",
                        float: "right",
                        bottom: "0px",
                      }
                }
                className="col-lg-12 d-flex justify-content-end py-4"
              >
                <button
                  onClick={() => handleClose()}
                  className="Cancelbutton mx-2"
                >
                  Cancel
                </button>
                <button onClick={saveQuestion} className="gridbutton">
                  Save
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="row m-0 mt-3">
        <div className="col-12 d-flex justify-content-end">
          <button onClick={() => handleOpen("")} className="ADDbutton">
            Add New Question
          </button>
        </div>
      </div>

      <div className="row m-0 mt-3">
        <div className="col-12">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid rows={rows} columns={columns} pageSize={10} />
          </Box>
        </div>
      </div>
    </>
  );
}
