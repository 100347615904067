const { submitQuestionLang } = require("../Global/globalFunction")

const SubscribeNow = ({ hidden }) => {
    const lang = localStorage.getItem("lang");
    return (
        <div className="row justify-content-center" hidden={hidden}>
        <div className="d-flex flex-column justify-content-center align-items-center subscribeBanner">
          <h5 className="text-center">
            <b>
              {lang
                ? submitQuestionLang(
                    lang,
                    "Want to Priority Your Question & Remove Ads?"
                  )
                : "Want to Priority Your Question & Remove Ads?"}
            </b>
          </h5>
          <a href={lang ? `https://buy.stripe.com/8wMbKK4tnfiW3gkaEF?locale=${lang}` : `https://buy.stripe.com/8wMbKK4tnfiW3gkaEF?locale=en`} target="_blank" rel="noreferrer" className="loginRegBtn w-50 mt-3 subscribeNowBtn">
            {lang
              ? submitQuestionLang(lang, "Subscribe now")
              : "Subcribe now"}
          </a>
        </div>
      </div>
    )
}

export default SubscribeNow;