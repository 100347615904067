import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import AdBlockerModal from "./AdBlockerModal";
import { useDetectAdBlock } from "adblock-detect-react";

function TermsOfUseFr() {
  const [open, setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  return (
    <>
      <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Conditions d'utilisation</h1>

        <h2>1. Avis de non-responsabilité générale</h2>
        <p>
          Les informations sur ce site web sont destinées uniquement à des fins
          générales d'information et ne doivent pas être considérées comme des
          conseils juridiques. Ce site web ne remplace pas un avis juridique
          professionnel. Consultez toujours un professionnel du droit agréé pour
          des questions juridiques spécifiques. Les informations sur ce site web
          peuvent être obsolètes ou incomplètes, et leur exactitude ne peut être
          garantie. Le propriétaire et les administrateurs de ce site web ne
          sont pas responsables des dommages résultant de l'utilisation des
          informations fournies.
        </p>

        <h2>2. Limitation de responsabilité</h2>
        <p>
          Ni le propriétaire du site web ni le personnel ne peuvent être tenus
          responsables des dommages ou pertes résultant de l'utilisation des
          informations sur ce site web. Les informations et conseils fournis
          sont basés sur des principes généraux et peuvent ne pas être
          spécifiques à votre situation.
        </p>

        <h2>3. Indépendance des conseils</h2>
        <p>
          Les opinions et conseils fournis sur ce site web ne représentent pas
          nécessairement les points de vue du propriétaire ou des
          administrateurs. Le site web peut inclure des conseils de diverses
          sources, y compris des contributions d'utilisateurs, mais dans ce cas,
          il n'y a pas de contributions d'utilisateurs.
        </p>

        <h2>4. Confidentialité et vie privée</h2>
        <p>
          Nous prenons votre vie privée au sérieux et nous nous efforçons de
          protéger toute information personnelle que vous partagez avec nous.
          Nous vous recommandons de consulter notre politique de confidentialité
          pour comprendre comment nous traitons vos données.
        </p>

        <h2>5. Propriété intellectuelle</h2>
        <p>
          Tout le contenu de ce site web est protégé par des droits d'auteur et
          d'autres droits de propriété intellectuelle. Copier, distribuer ou
          utiliser le contenu de ce site web sans permission est interdit.
        </p>

        <h2>6. Modifications des conditions d'utilisation</h2>
        <p>
          Le propriétaire se réserve le droit de modifier ces conditions
          d'utilisation à tout moment et sans préavis. Il incombe aux
          utilisateurs de vérifier régulièrement les mises à jour de ces
          conditions.
        </p>

        <h2>7. Dispositions finales</h2>
        <p>
          Ces conditions d'utilisation constituent l'accord complet entre vous
          et le propriétaire concernant votre utilisation de ce site web. Toute
          modification ou addition à ces conditions doit faire l'objet d'un
          accord écrit.
        </p>

        <p>
          En utilisant ce site web à des fins de conseils juridiques, vous
          acceptez ces conditions d'utilisation. Si vous n'êtes pas d'accord
          avec l'une de ces conditions, veuillez cesser votre utilisation du
          site web.
        </p>
      </div>

      <Footer />
    </>
  );
}
export default TermsOfUseFr;
