import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import AdBlockerModal from "./AdBlockerModal";
import { useDetectAdBlock } from "adblock-detect-react";
import TermsOfUseFr from "./TermsOfUseFr";
import TermsOfUseNl from "./TermsOfUseNl";

function TermsOfUse() {
  const [open, setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  const lang = localStorage.getItem("lang");
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const changeTermOfUse = () => {
    if (lang == "en") {
      return (
        <>
          <AdBlockerModal open={open} />
          <TopNav disableBackground={true} />
          <div className="policyContent container">
            <h1>Terms of Use</h1>
            <h2>1. General Disclaimer</h2>
            <p>
              The information on this website is intended solely for general
              informational purposes and should not be considered as legal
              advice. This website is not a substitute for professional legal
              advice. Always consult a licensed legal professional for specific
              legal issues. The information on this website may be outdated or
              incomplete, and its accuracy cannot be guaranteed. The owner and
              administrators of this website are not liable for any damages
              resulting from the use of the provided information.
            </p>

            <h2>2. Limitation of Liability</h2>
            <p>
              Neither the website owner nor the staff can be held liable for any
              damages or losses arising from the use of information on this
              website. The information and advice provided are based on general
              principles and may not be specific to your situation.
            </p>

            <h2>3. Independence of Advice</h2>
            <p>
              The opinions and advice provided on this website do not
              necessarily represent the views of the owner or administrators.
              The website may include advice from various sources, including
              user contributions, but in this case, there are no user
              contributions.
            </p>

            <h2>4. Confidentiality and Privacy</h2>
            <p>
              We take your privacy seriously and strive to protect any personal
              information you share with us. We recommend reviewing our privacy
              policy to understand how we handle your data.
            </p>

            <h2>5. Intellectual Property</h2>
            <p>
              All content on this website is protected by copyrights and other
              intellectual property rights. Copying, distributing, or using
              content from this website without permission is prohibited.
            </p>

            <h2>6. Changes in Terms of Use</h2>
            <p>
              The owner reserves the right to modify these terms of use at any
              time and without prior notice. It is the responsibility of users
              to regularly check for updates to these terms.
            </p>

            <h2>7. Final Provisions</h2>
            <p>
              These terms of use constitute the entire agreement between you and
              the owner regarding your use of this website. Any changes or
              additions to these terms must be agreed upon in writing.
            </p>

            <p>
              By using this website for legal advice, you agree to these terms
              of use. If you do not agree with any of these terms, please
              discontinue your use of the website.
            </p>
          </div>
          <Footer />
        </>
      );
    } else if (lang === "fr") {
      return <TermsOfUseFr />;
    } else if (lang === "nl") {
      return <TermsOfUseNl />;
    } else {
      return (
        <>
          <AdBlockerModal open={open} />
          <TopNav disableBackground={true} />
          <div className="policyContent container">
            <h1>Terms of Use</h1>
            <h2>1. General Disclaimer</h2>
            <p>
              The information on this website is intended solely for general
              informational purposes and should not be considered as legal
              advice. This website is not a substitute for professional legal
              advice. Always consult a licensed legal professional for specific
              legal issues. The information on this website may be outdated or
              incomplete, and its accuracy cannot be guaranteed. The owner and
              administrators of this website are not liable for any damages
              resulting from the use of the provided information.
            </p>

            <h2>2. Limitation of Liability</h2>
            <p>
              Neither the website owner nor the staff can be held liable for any
              damages or losses arising from the use of information on this
              website. The information and advice provided are based on general
              principles and may not be specific to your situation.
            </p>

            <h2>3. Independence of Advice</h2>
            <p>
              The opinions and advice provided on this website do not
              necessarily represent the views of the owner or administrators.
              The website may include advice from various sources, including
              user contributions, but in this case, there are no user
              contributions.
            </p>

            <h2>4. Confidentiality and Privacy</h2>
            <p>
              We take your privacy seriously and strive to protect any personal
              information you share with us. We recommend reviewing our privacy
              policy to understand how we handle your data.
            </p>

            <h2>5. Intellectual Property</h2>
            <p>
              All content on this website is protected by copyrights and other
              intellectual property rights. Copying, distributing, or using
              content from this website without permission is prohibited.
            </p>

            <h2>6. Changes in Terms of Use</h2>
            <p>
              The owner reserves the right to modify these terms of use at any
              time and without prior notice. It is the responsibility of users
              to regularly check for updates to these terms.
            </p>

            <h2>7. Final Provisions</h2>
            <p>
              These terms of use constitute the entire agreement between you and
              the owner regarding your use of this website. Any changes or
              additions to these terms must be agreed upon in writing.
            </p>

            <p>
              By using this website for legal advice, you agree to these terms
              of use. If you do not agree with any of these terms, please
              discontinue your use of the website.
            </p>
          </div>
          <Footer />
        </>
      );
    }
  };
  return <>{changeTermOfUse()}</>;
}
export default TermsOfUse;
