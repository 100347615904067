import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { forwardRef } from "react";

const GlobalAlert = (props) => {
    const { setAlertMessages, alertMessages }  = props;



    const Alert = forwardRef(function Alert(
        props,
        ref,
      ) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });
    
  /* Close Alert */
  const closeAlert = () => {
    setAlertMessages({
      message:'',
    status:'',
    flag:false,
    })
  }


    return (
    <>
      <Snackbar open={alertMessages.flag} autoHideDuration={6000} onClose={closeAlert}>
        <Alert onClose={closeAlert} severity={alertMessages.status} sx={{ width: '100%' }}>
          {alertMessages.message}
        </Alert>
      </Snackbar>
    </>
)
}


export default GlobalAlert;