import "./session.css";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Box, styled } from "@mui/system";
import axios from "axios";
import _ from "lodash";
import TopNav from "./TopNav";
import Footer from "./Footer";
import { homeScreenLang } from "../Global/globalFunction";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px", // Set your desired border radius here
          backgroundColor: "white",
        },
        input: {
          borderRadius: "10px !important",
          height: "48px",
        },
      },
    },
  },
});
const SingleQuestion = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [data,setData] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  console.log("navigate", location);
  useEffect(() => {
    console.log(window.location.href);
    const windowUrl = window.location.href.split('/');
    let urlId = ''
    if(windowUrl.length > 0)
    {
      urlId = windowUrl[4];
      questionAPI(urlId);
    }

  },[location?.state?.id]);



  const questionAPI = (id) => {
    let payload = {
      lang:lang,
    id:id
    }
    axios.post("https://juradvice.be/api/user/question_by_id.php",payload).then((response) => {
    if(response.data.status === "success")
    {
      setData(response.data.data);
    }
    }).catch((error) => {
      console.log("error",error); 
    })



  }



  const handleclick = () => {
    if (token) {
      navigate("/submit-question"); // Navigate to the desired route upon login
    } else {
      navigate("/login");
    }
  };
  return (
    <>
      <TopNav disableBackground={true} />
      <div className="container singleQuestion">
      
        <h1>
        {data && data.length > 0 ? data[0].question : ""}
        </h1>
        <p dangerouslySetInnerHTML={{ __html: data && data.length > 0 ? data[0].answer : ""}}>
          
        </p>
      </div>
      <div
          className="position-relative z-1 d-flex flex-column align-items-center justify-content-center"
          style={
            windowWidth <= 700
              ? { height: "50vh", backgroundColor: "white" }
              : { height: "50vh" }
          }
        >
          <h1 className="text-center fontColor mt-4">
            {lang
              ? homeScreenLang(lang, "Didn't Find Question?")
              : "Didn't Find Question?"}
          </h1>
          <button
            className={
              windowWidth <= 700
                ? "loginRegBtn mt-3 w-50"
                : "loginRegBtn mt-3 w-25"
            }
            onClick={handleclick}
          >
            {lang ? homeScreenLang(lang, "Submit Question") : "Submit Question"}
          </button>
        </div>
      <Footer />
    </>
  );
};

export default SingleQuestion;
