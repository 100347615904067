import { useState, useEffect } from "react";
import "./session.css";
import * as React from "react";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import GlobalAlert from "../Global/GlobalAlert";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
export default function Inbox() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const headerstyle = {
    color: "#ffffff",
    background: "#224589",
    height: "50px",
  };
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: false,
  });
  const [reply, setReply] = useState("");
  const [userQuestion, setUserQuestion] = useState("");
  const [questionId, setQuestionId] = useState("");
  const [deleteModal,setDeleteModal] = useState(false);
  const [messageId,setMessageId] = useState("");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const handleOpen = (messageId = "", question = "", answer = "") => {
    if (messageId !== "") {
      setQuestionId(messageId);
      setUserQuestion(question);
      setReply(answer);
    }
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    }
    setQuestionId("");
    setUserQuestion("");
    setReply("");
  };

  const openDeleteModal  = (id ="") => {
    setMessageId(id);
    setDeleteModal(true);
  }
  const columns = [
    {
      field: "message",
      headerName: "Question",
      width: window.innerWidth < 1024 ? 500 : 750,
      editable: false,
    },
    {
      field: "files",
      headerName: "Files",
      width: 400,
      editable: false,
      renderCell: (params) => {
        console.log("params",params)
        // let data = {...params}
        return (
          <div className="fileNames">{
            params.row.files && params.row.files.length > 0 ? 
          
            params.row.files.map((val) => (
              <a target="_blank" href={val.filepath}>{val.filename}</a>
            )) : <></>
          
          // <a href={params.row.file_path} target="blank" style={{textDecoration: 'underline'}}>{params.row.file_name}</a>
            }</div>
          )
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "subscription_status",
      headerName: "Status",
      width: 110,
      editable: false,
      renderCell: (params) => {
        const isVip = params.value === "VIP";
        return (
          <div className={`status-cell vipStatus ${isVip ? "vip-status" : ""}`}>
            {params.value}
          </div>
        );
      },
    },
    {
      field: "message_reply",
      headerName: "Reply Status",
      width: 110,
      editable: false,
      renderCell: (params) => {
        const isVip = params.value.trim().length > 0;
        return (
          <div className={`status-cell `}>
            {isVip ? "Answered" : "Not Answered"}
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      editable: false,
      description: "This column has a value getter and is not sortable.",
      renderCell: (e) => (
        <strong>
          <button
            onClick={() =>
              handleOpen(e.row.message_id, e.row.message, e.row.message_reply)
            }
            className="gridbutton"
          >
            Reply
          </button>
        </strong>
      ),

      sortable: false,
      width: 160,
    },
    {
      field: "deleteUser",
      headerName: "Action",
      width: 110,
      editable: false,
      renderCell: (e) => {
        return (
          <button className="deletebutton actionButtons" onClick={() => openDeleteModal(e.row.message_id)}> 
            <DeleteIcon  />
          </button>
        );
      },
    },
  ];

  const onChange = (event) => {
    const { value } = event.target;
    setReply(value);
  };

  useEffect(() => {
    if (token) {
      getInboxData();
    } else {
      navigate("/administrator");
    }
  }, []);

  const getInboxData = () => {
    let token = localStorage.getItem("token");
    axios
      .post("https://juradvice.be/api/message/inbox.php", "")
      .then((response) => {
        if (response.status === 200) {
          let responseData =
            response.data.data && response.data.data.length > 0
              ? response.data.data.map((val, index) => ({
                  ...val,
                  id: index,
                }))
              : [];

          setRows(responseData);
        }
      })
      .catch((error) => {
        setAlertMessages({
          message: "",
          status: "error",
          flag: true,
        });
        setRows([]);
      });
  };

  let className1;

  if (windowWidth <= 400) {
    className1 = "inboxmobilestyle";
  } else if (windowWidth < 900) {
    className1 = "inboxtabletstyle";
  } else {
    className1 = "inboxstyle";
  }
  const responsiveStyle1 =
    windowWidth <= 400
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "5%",
          top: "90px",
        }
      : windowWidth <= 900
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "25%",
          top: "10px",
        }
      : {
          borderRadius: "10px",
          padding: "0px",
          top: "100px",
        };

  const stylefield =
    windowWidth <= 400
      ? {
          borderRadius: "10px",
          width: 300,
        }
      : windowWidth <= 900
      ? {
          borderRadius: "10px",
          width: 360,
        }
      : {
          borderRadius: "10px",
          width: 700,
        };

  const sendMessage = () => {
    let requestData = {
      message_id: questionId,
      admin_reply: reply,
    };

    axios
      .post("https://juradvice.be/api/message/admin_reply.php", requestData)
      .then((response) => {
        if (response.status === 200) {
          setAlertMessages({
            message: response.data.message,
            status: "success",
            flag: true,
          });
          handleClose();

          getInboxData();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const deleteUser =() => {
    let requestData = {
      message_id:messageId
    }
    axios.post('https://juradvice.be/api/admin/delete_message.php',requestData).then((response) => {
      if(response.status === 200)
      {
        setAlertMessages({
          message:"Message Deleted Successfully",
          flag:true,
          status:'error'
        })
        setMessageId("");
        getInboxData();
        closeDeleteModal();
      }
    }).catch((error) => {
      console.log('error',error);
    })
  }
const closeDeleteModal = () => {
  setDeleteModal(false);
}

  return (
    <>
      <GlobalAlert
        alertMessages={alertMessages}
        setAlertMessages={setAlertMessages}
      />
   <Dialog
        open={deleteModal}
        onClose={closeDeleteModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Delete Question?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do You Want To Delete This Question ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteModal}>No</Button>
          <Button onClick={deleteUser} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={responsiveStyle1} className={className1}>
          <Typography
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              paddingLeft: "8px",
              paddingTop: "7px",
            }}
            sx={headerstyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <div className="m-3 my-1">
              <h4>Question</h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-30px",
              }}
            >
              <IconButton onClick={() => handleClose()} aria-label="Close">
                <CloseIcon
                  style={{ marginTop: "-10px", color: "#ffffff" }}
                  className="mx-2"
                />
              </IconButton>
            </div>
          </Typography>
          <div className="m-4">
            <div className="row">
              <div className="col-lg-3"></div>
              <div className="col-lg-9 d-flex justify-content-end">
                <h6 className="status">Priority: FREE</h6>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-12 d-flex">
                <SearchIcon className="searchBackground" fontSize="small" />
                <h6 className="mx-2">{userQuestion}?</h6>
              </div>
            </div>
            <div className="row mt-2 pb-2">
              <div className="col-lg-12">
                <TextField
                  style={stylefield}
                  name="reply"
                  multiline
                  onChange={onChange}
                  value={reply}
                  placeholder="Enter Reply"
                />
              </div>
            </div>
            <div className="row pb-4">
              <div className="col-lg-12">
                <button className="gridbutton" onClick={sendMessage}>
                  Send
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <div className="row m-0 mt-3">
        <div className="col-12">
          <Box sx={{ height: 600, width: "100%" }}>
            <DataGrid rows={rows} columns={columns} pageSize={10} />
          </Box>
        </div>
      </div>
    </>
  );
}
