import AdminGrid from "./sessions/AdminGrid";
import ChangePassword from "./sessions/ChangePassword";
import Inbox from "./sessions/Inbox";
import Questions from "./sessions/Questions";


const BasePage = [
  {
    path: "/users", element: <AdminGrid />,
  },
  {
    path: "/inbox", element: <Inbox />,
  },
  {
    path: "/questions", element: <Questions />,
  },
  {
    path: "/admin-change-password", element: <ChangePassword />,
  },
  
  
  
];

export default BasePage;
