import Loadable from 'app/components/Loadable';
import { lazy } from 'react';
import ForgotEmail from './ForgotEmail';
import Language from './Language';
import LegalQuestions from './LegalQuestions';
import SubmitQuestion from './SubmitQuestion';
import AdminGrid from './AdminGrid';
import Inbox from './Inbox';
import Questions from './Questions';
import ChangePassword from './ChangePassword';
import AdminLogin from './AdminLogin';
import { Navigate } from 'react-router-dom';
import MyQuestion from './MyQuestions';
import MyProfile from './MyProfile'
import SingleQuestion from './SingleQuestion';
import WhoAreWe from './WhoAreWe';
import TermsOfUse from './TermsOfUse';
import PrivacyPolicy from './PrivacyPolicy';
import OurPurpose from './OurPurpose';
import ActivateEmailScreen from './ActivateEmailScreen';

const ForgotPassword = Loadable(lazy(() => import('./ForgotPassword')));
const JwtLogin = Loadable(lazy(() => import('./JwtLogin')));

const sessionRoutes = [
  // Public Routes
  { path: '/', element: <Language /> },
  { path: '/login', element: <JwtLogin /> },
  { path: '/forgot-password', element: <ForgotEmail /> },
  { path: '/new-password', element: <ForgotPassword /> },
  { path: '/submit-question', element: <SubmitQuestion /> },
  { path: '/home', element: <LegalQuestions /> },
  { path: '/my-questions', element: <MyQuestion /> },
  {path:'/my-profile',element:<MyProfile /> },
  { path: '/who-we-are', element: <WhoAreWe /> },
  { path: '/terms-of-use', element: <TermsOfUse /> },
  { path: '/privacy-policy', element: <PrivacyPolicy /> },
  {path:'/question/:id/:question',element:<SingleQuestion />},
  {path: '/our-purpose', element:<OurPurpose />},

  // Admin Routes
  { path: '/AdminGrid', element: <AdminGrid /> },
  { path: '/inbox', element: <Inbox /> },
  { path: '/questions', element: <Questions /> },
  { path: '/admin-change-password', element: <ChangePassword /> },
  { path: '/administrator', element: <AdminLogin /> },
  {path:'/verify-email' ,element:<ActivateEmailScreen />},

  { path: '*', element: <Navigate to="/" /> }, // Redirect to the homepage if the route doesn't match any defined routes
];

export default sessionRoutes;
