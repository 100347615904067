import { createTheme, TextField, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import "./session.css";
import Order from "./Order.png";
import Logo from "./Logo.png";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { forwardRef } from "react";
import axios from "axios";
import GlobalAlert from "../Global/GlobalAlert";
import { loginLang } from "../Global/globalFunction";

const ForgotEmail = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: false,
  });
  const [btnDisable, setBtnDisable] = useState(false);

  let lang = localStorage.getItem("lang");
  if (lang) {
    localStorage.setItem("lang", lang);
  } else {
    localStorage.setItem("lang", "en");
  }

  const changeTab = (event) => {
    if (event === "SignUp") {
      setCurrentTab(true);
    }
  };

  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: "20px", // Set your desired border radius here
          },
          input: {
            borderRadius: "20px !important",
          },
        },
      },
    },
  });

  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const onChange = (event) => {
    const { value } = event.target;
    setEmailAddress(value);
  };

  const onBlur = (event) => {
    const { value } = event.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setAlertMessages({
        message: "Please enter a valid email address",
        status: "error",
        flag: true,
      });
      setBtnDisable(true);
    } else {
      setBtnDisable(false);
    }
  };

  const HandleClick = () => {
    let payload = {
      email: emailAddress,
    };
    let validation = forgetPasswordValidations(payload);
    if (validation) {
      axios.post("https://juradvice.be/api/account/user_forget.php", payload).then((response) => {
          if (response.status === 200) {
            
            setAlertMessages({
              message: response.data.message,
              status: response.data.status && response.data.status.length > 0 ? "success" : "error",
              flag: true,
            });
          
          
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };

  const HandleClicked = () => {
    navigate("/login"); // Navigate to the desired route upon login
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const forgetPasswordValidations = (payload) => {
    if (payload.email.trim() === "") {
      setAlertMessages({
        message: "Please enter your Email Address",
        status: "error",
        flag: true,
      });
      return false;
    }

    return true;
  };

  const handleKeyPressForgotPass = (event) => {
    if (event.key === 'Enter') {
      HandleClick();
    }
  }



  return (
    <>
      <GlobalAlert
        setAlertMessages={setAlertMessages}
        alertMessages={alertMessages}
      />
      <div className="d-flex align-items-center" style={{ height: "100vh" }}>
        <div className="loginSide">
          <img className="LoginImg" src={Order} />
        </div>
        <div className="loginFormSide">
          <div
            className="d-flex flex-column align-items-center"
            style={{
              gap: "10px",
            }}
          >
            <img style={{ width: "250px" }} src={Logo} />
            <div className="tabBackground">
              <div className={"button3"} onClick={() => changeTab("Login")}>
                {lang ? loginLang(lang, "Forgot password") : "Forgot Password"}
              </div>
            </div>
            <ThemeProvider theme={theme}>
              <div
                className={
                  windowWidth <= 700
                    ? "w-75 d-flex flex-column"
                    : "w-50 d-flex flex-column mt-5"
                }
              >
                <label>Email</label>
                <TextField
                  autoComplete="off"
                  className="mt-1 mb-3"
                  size="small"
                  InputProps={{
                    style: { borderRadius: "30px", border: "none" }, // Remove the border
                  }}
                  fullWidth
                  id="outlined-basic"
                  label=""
                  variant="outlined"
                  value={emailAddress}
                  onChange={onChange}
                  onBlur={onBlur}
                  onKeyDown={handleKeyPressForgotPass}
                />

                <button
                  className="loginRegBtn mt-3 forgetConfirm"
                  disabled={btnDisable}
                  onClick={HandleClick}
                >
                  {lang ? loginLang(lang, "Confirm") : "Confirm"}
                </button>
                <button className="loginwhiteBtn mt-3" onClick={HandleClicked}>
                {lang ? loginLang(lang, "Back") : "Back"}
                </button>
              </div>
            </ThemeProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotEmail;
