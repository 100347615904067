
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./session.css";
import English from './English.webp'
import France from './France.png'
import Dutch from './Dutch.png'
import LangBG from "./LangBG.webp";
import whitelogo from "./whitelogo.svg";


const Language = () => {
  const [selectedTab, setSelectedTab] = useState('english');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();
  const handleClickTab = (tab) => {
    setSelectedTab(tab);
    setLanguage(tab);
  
  navigate('/home');
  };


  const setLanguage = (lang) => {
    if(lang === 'english')
    {
      localStorage.setItem('lang','en');
    }
    else if(lang === 'france')
    {
      localStorage.setItem('lang','fr')
    }
    else {
      localStorage.setItem('lang','nl')
    }
  } 



  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <div className="d-flex flex-column-reverse justify-content-center align-items-center" style={{ height: "100vh" }}>
        <img className="StickyImage2" src={LangBG} />
        <div style={windowWidth <= 700 ? { width: "90%", marginTop: "40px" } : windowWidth <= 800 ? { width: "70%", marginTop: "40px" } : { width: "541px", marginTop: "40px", zIndex: "1" }}>
          <div style={{
            border: "1px solid #ffff",
            borderRadius: "15px"
          }}>
            <div className="card cardInner2">
              <div className="mt-3 card-body mb-3">
              </div>
            </div>
          </div>

        </div>
        <img style={{ height: "95px", width: "340px", zIndex: "1",objectFit:"cover" }} src={whitelogo} className="" />
        <div className={windowWidth <= 1000 ? "position-absolute" : "w-50 position-absolute"}>
          <div className="d-flex flex-column  z-1 position-relative  align-items-center" >
            <div className="divInnerContainer2">
              <div>
                <h4 style={{ color: "white", marginTop: "35%" }}>Kies uw taal</h4>
              </div>
              <div className="d-flex gap">
                <button
                  onClick={() => handleClickTab('english')}
                  className={'language-button'}
                >
                  <img className="imageHeight" src={English} />
                  <label className="mt-3">English</label>
                </button>


                <button
                  onClick={() => handleClickTab('france')}
                  className={ 'language-button' }
                >
                  <img className="imageHeight" src={France} />
                  <label className="mt-3">Français</label>
                </button>

                <button
                  onClick={() => handleClickTab('dutch')}
                  className={'language-button'}
                >
                  <img className="imageHeight" src={Dutch} />
                  <label className="mt-3">Nederlands</label>
                  
                </button>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Language;
