import "./session.css";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";
import { Modal } from "@mui/material";
import { Box, styled } from "@mui/system";
import TopNav from "./TopNav";
import Footer from "./Footer";
import axios from "axios";
import _ from "lodash";
import { useDetectAdBlock } from "adblock-detect-react";
import { homeScreenLang } from "../Global/globalFunction";
import AdblockImg from "./assets/adblocker.png";
import AdBlockerModal from "./AdBlockerModal";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px", // Set your desired border radius here
          backgroundColor: "white",
        },
        input: {
          borderRadius: "10px !important",
          height: "48px",
        },
      },
    },
  },
});

function LegalQuestions(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const token = localStorage.getItem('token');

  const adBlockDetected = useDetectAdBlock();
  const navigate = useNavigate();

  const handleclick = () => {
    if (token) {
      navigate("/submit-question"); // Navigate to the desired route upon login
    } else {
      navigate("/login");
    }
  };

  const [open, setOpen] = useState(false);
  const lang = localStorage.getItem("lang");

  useEffect(() => {

    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  
  
  





  return (
    <>

<AdBlockerModal open={open} />
      <div style={{ overflow: "hidden" }}>
        <TopNav disableBackground={true} />

        <div
          className="position-relative z-1 d-flex flex-column align-items-center justify-content-center"
          style={
            windowWidth <= 700
              ? { height: "50vh", backgroundColor: "white" }
              : { height: "50vh" }
          }
        >
          <h1 className="text-center fontColor mt-4">
            {lang
              ? homeScreenLang(lang, "Didn't Find Question?")
              : "Didn't Find Question?"}
          </h1>
          <button
            className={
              windowWidth <= 700
                ? "loginRegBtn mt-3 w-50"
                : "loginRegBtn mt-3 w-25"
            }
            onClick={handleclick}
          >
            {lang ? homeScreenLang(lang, "Submit Question") : "Submit Question"}
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default LegalQuestions;
