import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";

function PrivacyPolicyNl() {
  return (
    <>
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Privacy- en Cookieverklaring</h1>
        <p>
          Deze "Privacy Policy" reguleert de verwerking van persoonsgegevens
          door de verantwoordelijke voor de verwerking: Juradvice (hierna:
          "Verantwoordelijke," "Wij," of "Juradvice"). Lees deze Privacy Policy
          zorgvuldig door, omdat het essentiële informatie bevat over hoe uw
          persoonsgegevens worden verwerkt en welke cookies worden gebruikt.
          Door uw persoonsgegevens op de website te verstrekken en door gebruik
          te maken van de op deze website vermelde emailadressen voor het
          verschaffen van persoonsgegevens, verklaart u kennis te hebben genomen
          van deze Privacy Policy en gaat u er eveneens uitdrukkelijk mee
          akkoord, samen met de verwerking zelf.
        </p>

        <h2>Artikel 1 – Algemeen</h2>
        <p>
          Juradvice volgt de ‘Wet van 8 december 1992 tot bescherming van de
          persoonlijke levenssfeer ten opzichte van de verwerking van
          persoonsgegevens,’ zoals gewijzigd door de Wet van 11 december 1998,
          en de anti-spambepalingen uit Boek XII van het Wetboek Economisch
          Recht met betrekking tot het “recht van de elektronische economie”.
          Juradvice conformeert zich ook aan de Europese Verordening 2016/679
          van 27 april 2016 betreffende de bescherming van persoonsgegevens. De
          verantwoordelijke voor de verwerking van uw persoonsgegevens is
          Juradvice, met een verklaring bij de Commissie voor de Bescherming van
          de Persoonlijke Levenssfeer
        </p>

        <h2>Artikel 2 – Persoonsgegevens</h2>
        <p>
          2.1. Persoonsgegevens die u ons meedeelt: Uw IP-adres, surfgedrag,
          herkomst, zoektermen, profielnaam, wachtwoord, e-mailadres,
          logingegevens, profielafbeelding, contactgegevens, persoonsgegevens,
          en via cookies worden bepaalde gegevens verzameld (zie artikel 9 voor
          cookiebeleid).
        </p>

        <p>
          2.2. Wijze van gegevensvergaring: Dit omvat het gebruik van cookies,
          registratie of gebruik van het platform, het vragen om nadere
          informatie na contact met Juradvice, het invullen van formulieren voor
          probleembespreking, en het delen van gegevens met andere gebruikers op
          onze sites.
        </p>

        <p>
          2.3. Cookies: De website maakt gebruik van cookies voor
          gegevensverzameling. Zie artikel 9 voor cookiebeleid.
        </p>

        <h2>Artikel 3 – Doeleinden van verwerking</h2>
        <p>
          3.1. Algemene doeleinden: Juradvice zal persoonsgegevens uitsluitend
          gebruiken voor het verbeteren van het forum, beheer van uw account,
          direct marketing, beantwoorden van vragen, en verificatie van
          gegevens. Zie artikel 9 voor cookiebeleid.
        </p>

        <p>
          3.2. Direct marketing: Gegevens worden gebruikt voor direct marketing
          met uw expliciete toestemming ("opt-in"). U kunt op elk moment deze
          toestemming intrekken.{" "}
        </p>

        <p>
          3.3. Doorgifte aan derden: Bij reorganisatie of overdracht van
          activiteiten van Juradvice kunnen gegevens worden overgedragen aan
          nieuwe entiteiten of derden. Juradvice verkoopt, verhuurt, of deelt uw
          persoonsgegevens niet commercieel zonder uw toestemming.
        </p>

        <p>
          3.4. Wettelijke vereisten: In zeldzame gevallen kan Juradvice
          persoonsgegevens onthullen om te voldoen aan gerechtelijke bevelen of
          dwingende wet- of regelgeving.
        </p>

        <h2>Artikel 4 – Duur van verwerking</h2>
        <p>
          Persoonsgegevens worden bewaard en verwerkt zolang nodig voor de
          verwerkingsdoeleinden en de contractuele relatie tussen Juradvice en
          u.
        </p>

        <h2>Artikel 5 – Uw rechten</h2>
        <p>
          5.1. Recht van toegang en inzage: U heeft het recht om gratis kennis
          te nemen van uw persoonsgegevens en het gebruik ervan door Juradvice.
        </p>

        <p>
          5.2. Recht van verbetering, verwijdering en beperking: U heeft het
          recht om persoonsgegevens te laten verbeteren, aanvullen of
          verwijderen, en kunt vragen om de verwerking ervan te beperken.
        </p>

        <p>
          5.3. Recht van verzet: U heeft het recht van verzet tegen de
          verwerking van uw persoonsgegevens en tegen gebruik voor direct
          marketing zonder opgaaf van redenen. Μ
        </p>

        <p>
          5.4. Recht van intrekking van toestemming: Voor verwerking op basis
          van toestemming kunt u de toestemming op elk moment intrekken.
        </p>

        <p>
          5.5. Uitoefening van uw rechten: Rechten kunnen worden uitgeoefend
          door contact op te nemen via info@Juradvice.be.
        </p>

        <p>
          5.6. Meer info: Voor meer informatie over privacyrechten en de
          verwerking van persoonsgegevens, kunt u contact opnemen met de
          Belgische Privacycommissie
        </p>

        <h2>Artikel 6 – Veiligheid en vertrouwelijkheid</h2>
        <p>
          6.1. Juradvice heeft veiligheidsmaatregelen getroffen om
          persoonsgegevens te beschermen tegen vernietiging, verlies,
          vervalsing, wijziging, ongeoorloofde toegang, of onthulling aan
          derden.
        </p>

        <p>
          6.2. Juradvice is niet aansprakelijk voor schade door foutief of
          onrechtmatig gebruik van persoonsgegevens door derden.
        </p>

        <p>
          6.3. U dient de veiligheidsvoorschriften na te leven, zoals het
          voorkomen van ongeautoriseerde toegang tot uw login en code. U bent
          zelf verantwoordelijk voor het gebruik van de website vanaf uw
          computer, IP-adres en identificatiegegevens, en voor de
          vertrouwelijkheid ervan.
        </p>

        <h2>Artikel 7 – Toegang door derden</h2>
        <p>
          Juradvice verleent toegang tot persoonsgegevens aan medewerkers, met
          garanties voor beschermingsniveau volgens deze Privacy Policy.
        </p>

        <h2>Artikel 8 – Cookies</h2>
        <p>
          8.1. Wat zijn cookies: Een "cookie" is een klein bestand verzonden
          door de server van Juradvice en geplaatst op de harde schijf van uw
          computer. Informatie op deze cookies is leesbaar tijdens het bezoek
          aan de website.
        </p>

        <p>
          8.2. Waarom gebruiken we cookies: Cookies en vergelijkbare
          technologieën onderscheiden gebruiksvoorkeuren, verbeteren de
          gebruikerservaring, en optimaliseren de website. Toestemming wordt
          gevraagd bij het eerste bezoek, en cookie-instellingen kunnen worden
          gewijzigd.
        </p>

        <p>
          8.3. Soorten cookies: Functionele of noodzakelijke cookies worden
          gebruikt zonder voorafgaande toestemming. Andere cookies, zoals
          performance en advertising cookies, vereisen uw toestemming. Juradvice
          behoudt zich het recht voor om een script te plaatsen voor het
          detecteren van ad blockers.
        </p>

        <h2>Verantwoordelijke voor het beheer van deze website:</h2>
        <p>
          Juradvice
          <br />
          E-mail:{" "}
          <a href="mailto:info@juradvice.be">
            {" "}
            <u>info@juradvice.be</u>
          </a>
        </p>
      </div>
      <Footer />
    </>
  );
}
export default PrivacyPolicyNl;
