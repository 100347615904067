import React, { useEffect, useState } from "react";
import MissionImg from "./assets/mission.png";
import PurposeImg from "./assets/purpose.jpg";
import Icon1 from "./assets/icon1.png";
import Icon2 from "./assets/icon2.png";
import Icon3 from "./assets/icon3.png";
import { useDetectAdBlock } from "adblock-detect-react";

const OurPurposeNl = () => {
  const [open, setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);
  return (
    <div className="policyContent container">
      <h1>Onze Missie</h1>
      <div className="d-flex whoArewe align-items-center">
        <img src={PurposeImg} alt="Bord met wet" />
        <p>
          Bij Juradvice geloven we dat toegang tot juridisch advies en
          informatie geen voorrecht is, maar een fundamenteel recht. We
          begrijpen dat het navigeren door de complexiteiten van de juridische
          wereld intimiderend en overweldigend kan zijn. Daar komen wij in
          beeld.
        </p>
      </div>
      <div className="d-flex whoArewe whoArewe2 align-items-center mt-4">
        <p>
          Onze missie is eenvoudig maar diepgaand: we zijn hier om u te voorzien
          van de kennis en middelen die u nodig heeft om geïnformeerde
          beslissingen te nemen, uw rechten te begrijpen en met vertrouwen de
          juridische uitdagingen aan te gaan die het leven op uw pad kan
          brengen.
        </p>
        <img src={MissionImg} alt="Onze Missie" />
      </div>
      <div className="heartofcomitment text-center">
        <h2>Het Hart van Onze Toewijding</h2>
        <div className="d-flex justify-content-between">
          <div className="iconBox">
            <img src={Icon1} alt="Toegankelijkheid" />
            <h3>Toegankelijkheid</h3>
            <p>
              We hebben een brug gebouwd over de kloof tussen de complexiteiten
              van de wet en uw begrip. We zijn toegewijd aan het toegankelijk
              maken van juridisch advies en informatie voor iedereen, ongeacht
              hun achtergrond, middelen of ervaring.
            </p>
          </div>
          <div className="iconBox">
            <img src={Icon2} alt="Deskundigheid" />
            <h3>Deskundigheid</h3>
            <p>
              Ons team bestaat uit juridische experts die gepassioneerd zijn
              over het vereenvoudigen van de wet voor u. Ze brengen een schat
              aan ervaring en kennis mee, klaar om uw vragen te beantwoorden en
              inzichten te bieden die u kunnen begeleiden bij complexe
              juridische kwesties.
            </p>
          </div>
          <div className="iconBox">
            <img src={Icon3} alt="Autonomie" />
            <h3>Autonomie</h3>
            <p>
              We geloven sterk dat kennis macht is. Door u te voorzien van de
              juridische knowhow die u nodig heeft, streven we ernaar u in staat
              te stellen geïnformeerde beslissingen te nemen, uw rechten te
              beschermen en met vertrouwen juridische obstakels te overwinnen.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPurposeNl;
