import "./session.css";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import QuizIcon from "@mui/icons-material/Quiz";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/system";
import TopNav from "./TopNav";
import { TextField } from "@mui/material";
import Footer from "./Footer";
import axios from "axios";
import { myProfileLang } from "../Global/globalFunction";
import { submitQuestionLang } from "../Global/globalFunction";
import AdBlockerModal from "./AdBlockerModal";
import { useDetectAdBlock } from "adblock-detect-react";
import GlobalAlert from "../Global/GlobalAlert";
import SubscribeNow from "./SubscribeNow";

const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px", // Set your desired border radius here
          backgroundColor: "white",
        },
        input: {
          borderRadius: "10px !important",
        },
      },
    },
  },
});

function MyQuestion(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(null);
  const [profileData, setProfileData] = useState({});
  const [open,setOpen] = useState(false);
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: "",
  });
  const navigate = useNavigate();
  const adBlockDetected = useDetectAdBlock();
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");
  const lang = localStorage.getItem("lang");
  const subscription = localStorage.getItem("subscription");
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
    if (!token) {
      navigate("/login");
    } else {
      getProfileData();
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getProfileData = () => {
    let requestData = {
      email: userEmail,
    };

    axios
      .post("https://juradvice.be/api/user/package_detail.php", requestData)
      .then((response) => {
        if (response.status === 200) {
          console.log("Profile", response.data.data);
          setProfileData(response.data.data);
        }
      });
  };
  const cancelSubscription = () => {
    let email = userEmail ? userEmail : '';
    if(email !== '')
    {
      let payload = {
        email:email
      };
      axios.post('https://juradvice.be/api/user/cancel_subscription.php',payload).then((response) => {
        if(response.status === 200)
        {
            setAlertMessages({
              message:'Your subscription cancelled succesfully',
              status:'success',
              flag:true
            });
        }
      }).catch((error) => {
        setAlertMessages({
          message:'Something went wrong',
          status:'error',
          flag:true
        });

      })
    }

  }
  return (
    <>
    <AdBlockerModal open={open} />
    
      <TopNav disableBackground={true} />
      <GlobalAlert
            setAlertMessages={setAlertMessages}
            alertMessages={alertMessages}
          />
      <div className="myProfileDiv container">
        <h2>{lang ? myProfileLang(lang, "My Profile") : "My Profile"}</h2>
        <p>
          <strong>
            {lang ? myProfileLang(lang, "Full Name") : "Username"}
          </strong>
          : {profileData.fullname}
        </p>
        <p>
          <strong>Email</strong>: {profileData.email}
        </p>
        <p>
          <strong
            className={
             subscription && subscription !== "free"
                ? "vipStat"
                : "freeStat"
            }
          >
            { subscription && subscription !== "free"
              ? `${
                  lang
                    ? myProfileLang(lang, "Subscription Status")
                    : "Subscription Status"
                }: VIP`
              : `${
                  lang
                    ? myProfileLang(lang, "Subscription Status")
                    : "Subscription Status"
                }: ${lang ? myProfileLang(lang, "Free") : "Free"}`}
          </strong>
        </p>
        <p>
          { subscription && subscription !== "free"
            ? (lang ? myProfileLang(lang, "Subscription MessageVIP") : "Great! You are already subscribed. Enjoy being prioritized.")
            : (lang ? myProfileLang(lang, "Subscription Message") : "Oops! You don't have subscription. Your questions won't be prioritized.")}
        </p>
        <p hidden={profileData.user_status === "none"}>
          {lang ? myProfileLang(lang, "Amount") : "Amount"}:{" "}
          {profileData.package_amount}
        </p>
        <p hidden={profileData.user_status === "none"}>
          {lang
            ? myProfileLang(lang, "Subscription Created at")
            : "Subscription Created at"}
          : {profileData.subscription_created_at}
        </p>
        <p hidden={profileData.user_status === "none"}>
          {lang
            ? myProfileLang(lang, "Subscription Ends On")
            : "Subscription Expriy Date"}
          : {profileData.subscription_expires_at}
        </p>
        {subscription && subscription !== "free" ? (
          <button className="cancelSubButton" onClick={cancelSubscription}>Cancel Subscription</button>
        ) : (
          <></>
        )}

        {/* promo banner */}
        <SubscribeNow hidden={subscription &&  subscription !== 'free'} /> 
        
      </div>

      <Footer />
    </>
  );
}

export default MyQuestion;
