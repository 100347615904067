import {  Modal } from "@mui/material";

const AdBlockerModal = (props) => {
    return (
        <Modal
        open={props.open}
        onClose={false}
        disableBackdropClick
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="text-center" style={{ backgroundColor:'white' }}>
      <h1>You cannot Access This Website, Please Remove AdBlocker</h1>
      </div>
      </Modal>
    )
}


export default AdBlockerModal;