import { Card, Grid, styled, useTheme } from '@mui/material';
import { Fragment,useEffect } from 'react';
import Sidenav from 'app/components/Sidenav';
import Layout1Sidenav from 'app/components/MatxLayout/Layout1/Layout1Sidenav';
import Layout1Topbar from 'app/components/MatxLayout/Layout1/Layout1Topbar';
import Layout1 from 'app/components/MatxLayout/Layout1/Layout1';
const Analytics = () => {
  const ContentBox = styled('div')(({ theme }) => ({
    margin: '30px',
    paddingBottom: '40px',
    [theme.breakpoints.down('sm')]: { margin: '16px' },
  }));
  


  return (
    <>
    <Layout1/>
    </>
  )
};

export default Analytics;
