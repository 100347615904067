import React, { useEffect, useState } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import MissionImg from "./assets/mission.png";
import PurposeImg from "./assets/purpose.jpg";
import Icon1 from "./assets/icon1.png";
import Icon2 from "./assets/icon2.png";
import Icon3 from "./assets/icon3.png";
import { useDetectAdBlock } from "adblock-detect-react";
import AdBlockerModal from "./AdBlockerModal";
import OurPurposeFr from "./OurPurposeFr";
import OurPurposeNl from "./OurPurposeNl";


function OurPurpose() {
  const [open,setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  const lang = localStorage.getItem('lang')
  useEffect(()=> {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  },[]);

  const changePurpose = () => {
    if(lang === "en")
    {
      return (
        <>
        <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Our Purpose</h1>
        <div className="d-flex whoArewe align-items-center">
          <img src={PurposeImg} alt="Law Sign" />
          <p>
            At Juradvice, we believe that access to legal advice and information
            is not a privilege, but a fundamental right. We understand that
            navigating the intricacies of the legal world can be intimidating
            and overwhelming. That's where we come in.
          </p>
        </div>
        <div className="d-flex whoArewe whoArewe2 align-items-center mt-4">
          <p>
            Our mission is simple, yet profound: We are here to empower you with
            the knowledge and resources you need to make informed decisions,
            understand your rights, and confidently face the legal challenges
            that life may throw your way.
          </p>
          <img src={MissionImg} alt="Our Mission" />
        </div>
        <div className="heartofcomitment text-center">
          <h2>The Heart of Our Commitment</h2>
          <div className="d-flex justify-content-between">
            <div className="iconBox">
              <img src={Icon1} alt="Accessibility" />
              <h3>Accessibility</h3>
              <p>
                We have built a bridge over the gap between the intricacies of
                law and your understanding. We're committed to making legal
                advice and information accessible to everyone, regardless of
                their background, resources, or experience.
              </p>
            </div>
            <div className="iconBox">
              <img src={Icon2} alt="Expertise" />
              <h3>Expertise</h3>
              <p>
                Our team comprises legal experts who are passionate about
                simplifying the law for you. They bring a wealth of experience
                and knowledge, ready to answer your questions and provide
                insights that can guide you through complex legal matters.
              </p>
            </div>
            <div className="iconBox">
              <img src={Icon3} alt="Empowerment" />
              <h3>Empowerment</h3>
              <p>
                We firmly believe that knowledge is power. By arming you with
                the legal know-how you need, we aim to empower you to make
                informed decisions, protect your rights, and overcome legal
                obstacles with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
      )

    }
    else if(lang === "fr")
    {
      return (
        <>
         <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <OurPurposeFr />
      <Footer />
      </>)
    }
    else if(lang === "nl") 
    {
      return (
        <> <AdBlockerModal open={open} />
        <TopNav disableBackground={true} />
        <OurPurposeNl />
        <Footer />
        </>
      )
    }
    else {
      return (
        <>
        <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Our Purpose</h1>
        <div className="d-flex whoArewe align-items-center">
          <img src={PurposeImg} alt="Law Sign" />
          <p>
            At Juradvice, we believe that access to legal advice and information
            is not a privilege, but a fundamental right. We understand that
            navigating the intricacies of the legal world can be intimidating
            and overwhelming. That's where we come in.
          </p>
        </div>
        <div className="d-flex whoArewe whoArewe2 align-items-center mt-4">
          <p>
            Our mission is simple, yet profound: We are here to empower you with
            the knowledge and resources you need to make informed decisions,
            understand your rights, and confidently face the legal challenges
            that life may throw your way.
          </p>
          <img src={MissionImg} alt="Our Mission" />
        </div>
        <div className="heartofcomitment text-center">
          <h2>The Heart of Our Commitment</h2>
          <div className="d-flex justify-content-between">
            <div className="iconBox">
              <img src={Icon1} alt="Accessibility" />
              <h3>Accessibility</h3>
              <p>
                We have built a bridge over the gap between the intricacies of
                law and your understanding. We're committed to making legal
                advice and information accessible to everyone, regardless of
                their background, resources, or experience.
              </p>
            </div>
            <div className="iconBox">
              <img src={Icon2} alt="Expertise" />
              <h3>Expertise</h3>
              <p>
                Our team comprises legal experts who are passionate about
                simplifying the law for you. They bring a wealth of experience
                and knowledge, ready to answer your questions and provide
                insights that can guide you through complex legal matters.
              </p>
            </div>
            <div className="iconBox">
              <img src={Icon3} alt="Empowerment" />
              <h3>Empowerment</h3>
              <p>
                We firmly believe that knowledge is power. By arming you with
                the legal know-how you need, we aim to empower you to make
                informed decisions, protect your rights, and overcome legal
                obstacles with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
      )
    }
  }


  return (
    <>
    { changePurpose()}
</>
  )
}
export default OurPurpose;
