import { useEffect, useState } from "react";
import { loginLang } from "../Global/globalFunction";
import { useNavigate } from "react-router-dom";
import warningIcon from "./assets/warning.png";
import verifiedIcon from "./assets/verified.png";
import "./session.css";
import axios from "axios";
const ActivateEmailScreen = () => {
  const [activateSuccess, setActivateSuccess] = useState(false);
  const lang = localStorage.getItem("lang");
  const navigate = useNavigate();

  useEffect(() => {
    activateEmailAPI();
  }, []);

  const activateEmailAPI = () => {
    var currentUrl = window.location.href;

    // Use URLSearchParams to get the value of 'token'
    var urlParams = new URLSearchParams(currentUrl.split("?")[1]); // Split to get the query string part
    var token = urlParams.get("token");

    let payload = {
      token: token,
    };

    axios
      .post("https://juradvice.be/api/account/userVerify.php", payload)
      .then((response) => {
        if (response.data.status === "sucess") {
          console.log("Response", response.data);
          setActivateSuccess(true);
        } else {
          setActivateSuccess(false);
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  const login = () => {
    navigate("/login");
  };

  return (
    <div className="activateEmailBox">
      <div className="innerBoxAE">
        {activateSuccess ? (
            <>
            <img alt="verified" src={verifiedIcon} />
            <h5>Your email has been verified successfully</h5>
            </>

        ) : (
            <>
            <img alt="not verified" src={warningIcon} />
            <h5>Oops! There is an error verifying your email</h5>
            </>
        )}

        <button className="loginBtn mt-3" onClick={login}>
          {lang ? loginLang(lang, "Login") : "Login"}
        </button>
      </div>
    </div>
  );
};

export default ActivateEmailScreen;
