import { Card, Checkbox, Grid, Modal, TextField, Typography, FormControl, MenuItem, InputLabel, Select, FormControlLabel, createTheme, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import "./session.css";
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import "./session.css";
import axios from "axios";
import GlobalAlert from "../Global/GlobalAlert";

const ChangePassword = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [formData,setFormData] = useState({
    email:"",
    old_password:"",
    new_password:"",
    confirm_password:""
  });
  const [alertMessages,setAlertMessages] = useState({
    message:'',
    status:'',
    flag:false
  });
  const [btnDisable,setBtnDisable] = useState(false);

    const theme = createTheme({
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: "20px", // Set your desired border radius here
                borderColor: "#224589"
            },
              input: {
                borderRadius: "20px !important",
                borderColor: "#224589"

              },
            },
          },
        },
      });
const navigate = useNavigate();
const token = localStorage.getItem('token');
      useEffect(() => {
          if(!token)
          {
              navigate('/login')
          }
      },[])
  
  
    const handleClickShowPassword = () => setShowPassword((show) => !show);
      
    /* On Change */
    const onChange = (event) => {
      const {name, value} = event.target;
      setFormData((prevState) => ({
        ...prevState,
        [name]:value,
      }))
    }

    /* On Blur */
    const onBlur = (event) => {
      const {name,value} = event.target;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(value)) {
    setAlertMessages({
     message:'Please enter a valid email address',
     status:'error',
     flag:true
    })
     setBtnDisable(true);
 }
 else {
   setBtnDisable(false)
 }
    }
  
    
    const validation = () => {
      if(formData.old_password === '')
      {
        setAlertMessages({
          status:'error',
          message:'Please Enter Your Current Password',
          flag:true
        });
        return false;
      }
      if(formData.new_password === '')
      {
        setAlertMessages({
          status:'error',
          message:'Please Enter Your New Password',
          flag:true
        })
        return false;
      } 
      if(formData.confirm_password.toLowerCase() !== formData.new_password.toLowerCase())
      {
        setAlertMessages({
          status:'error',
          message:'Your Confirm Password should be same as new Password',
          flag:true
        })
        return false;
      }
      
      return true;
    }
    
    
    
    /* change Password api */
    const changePassword = () => {
      let checkValidations = validation();
      let email = localStorage.getItem('email');
      let payload = {
        ...formData,
        email:email ? email : ''
      };

      if(checkValidations)
      {
        axios.post('https://juradvice.be/api/admin/admin_reset_password.php',payload).then((response) => {
          if(response.status === 200)
          {
            if(response.data.status !== 'error')
          
            {
            setAlertMessages(({
              message:"Your Password has been changed successfully",
              status:'success',
              flag:true
            }));
            setFormData({
              old_password:'',
              new_password:'',
              confirm_password:'',
            })
         
          }
          else {
            setAlertMessages(({
              message:response.data.message,
              status:'error',
              flag:true
            }));

          }
        }


        }).catch((error) => { 

          setAlertMessages(({
            message:error.message,
            status:'error',
            flag:true
          }));
        })
      }
     
    }

  
  
  return (
    <>
<GlobalAlert
alertMessages={alertMessages}
setAlertMessages={setAlertMessages}


/>
          <div className="fieldmodel">
        <div className="mt-3 mb-5">
            <h4 className="passwordtabBackground mx-2 pt-2">Change Admin Password</h4>
         
          </div>
          <ThemeProvider theme={theme}>
            <div style={
              windowWidth < 400 ?
              {
                width: "320px",
                marginLeft:"0px"

              }:
              {

              }
            } className="row">

            <label>Current Password</label>
            <FormControl
              className="mb-3"
              fullWidth
              variant="outlined"
              size="small"
          
       
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="old_password"
                onChange={onChange}
                value={formData.old_password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className="row" style={
              windowWidth < 400 ?
              {
                width: "320px",
                marginLeft:"0px"

              }:
              {

              }
            } >
            <label>New Password</label>
            <FormControl
              fullWidth
              className="mb-3"
              variant="outlined"
              size="small"
          
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="new_password"
                onChange={onChange}
                value={formData.new_password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            </div>
          <div className="row"style={
              windowWidth < 400 ?
              {
                width: "320px",
                marginLeft:"0px"

              }:
              {

              }
            } >
            <label>Confirm Password</label>
            <FormControl
              fullWidth
              className="mb-3"
              variant="outlined"
              size="small"
              
            >
              <OutlinedInput
                id="outlined-adornment-password"
                type={showPassword ? "text" : "password"}
                name="confirm_password"
              value={formData.confirm_password}
              onChange={onChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>
          <div style={ windowWidth < 400 ?
              {
                width: "320px",
                marginLeft:"0px"

              }:
              {

              }} className="row">
          <button className="Submitbutton" disabled={btnDisable} 
           onClick={changePassword}
           
           >
            Submit
        </button>
          </div>
          </ThemeProvider>
          </div>
    </>
  );
};

export default ChangePassword;
