import "./session.css";
import { useEffect, useState } from "react";
import QuizIcon from "@mui/icons-material/Quiz";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import { useNavigate } from "react-router-dom";
import TopNav from "./TopNav";
import Footer from "./Footer";
import axios from "axios";
import { myQuestionLang } from "../Global/globalFunction";
import SubscribeNow from "./SubscribeNow";


function MyQuestion(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(null);
  const [userQuestionData, setUserQuestionData] = useState([]);

  const navigate = useNavigate();
  const userEmail = localStorage.getItem("email");
  const token = localStorage.getItem("token");
  const lang = localStorage.getItem("lang");
  const subscription_status = localStorage.getItem("subscription");
  const handleclick = () => {
    navigate("/submit-question"); // Navigate to the desired route upon login
  };
  useEffect(() => {
    if (token) {
      getUserQuestionData();
    } else {
      navigate("/login");
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getUserQuestionData = () => {
    let requestData = {
      email: userEmail,
    };
    axios
      .post("https://juradvice.be/api/user/user_profile.php", requestData)
      .then((response) => {
        if (response.status === 200) {
          let data = response.data.data.map((val) => ({
            ...val,
            comment: "",
          }));

          setUserQuestionData(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUserQuestionData((prevState) => [
      ...prevState,
      {
        ...userQuestionData,
        [name]: value,
      },
    ]);
  };

  return (
    <>
      <TopNav disableBackground={true} />
      <div className="container">
        {userQuestionData && userQuestionData.length > 0 ? (
          userQuestionData.map((val) => (
            <div className="myQuestionsCont">
              {/* question by user and question status  */}
              <div className="d-flex justify-content-between questionStatusCont">
                <div className="d-flex" style={{ gap: "15px" }}>
                  {" "}
                  <QuizIcon color="secondary" />
                  <h5 className="rc-accordion-title lh-base questionAsked">
                    {val.message !== ""
                      ? val.message
                      : lang
                        ? myQuestionLang(lang, "No Question Asked From You")
                        : "No Question Asked From You"}{" "}
                  </h5>
                </div>
                <div className="questionStatus text-end">
                  <p className={val.status === "Answered" ? 'answered' : 'pending'}>
                    Status:{" "}
                    {val.status !== ""
                      ? val.status === "Answered"
                        ? lang
                          ? myQuestionLang(lang, "Answered")
                          : "Answered"
                        : lang
                        ? myQuestionLang(lang, "Pending")
                        : "Pending"
                      : "N/A"}
                  </p>
                </div>
              </div>
              {/* files links */}
              <div className="mt-3 filesUpload">
                <h5>
                  {lang
                    ? myQuestionLang(lang, "Files Uploaded")
                    : "Files Uploaded:"}
                </h5>
                {val.file_path.split(",").map((filePath, index) => {
                  const fileNames = val.file_name.split(",");
                  return (
                    <a key={index} href={filePath} download={filePath}>
                      <p>{fileNames[index]}</p>
                    </a>
                  );
                })}
              </div>

              {/* question by admin  */}
              <div
                className="d-flex answerAdmin mt-3"
                style={{ height: "auto" }}
              >
                <EmojiObjectsIcon color="primary" />
                <div className="rc-accordion-body">
                  <p className="mb-0 messageReply">
                    {" "}
                    {val.message_reply
                      ? val.message_reply
                      : lang
                      ? myQuestionLang(lang, "Message Reply")
                      : "Haven't answered yet"}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
        {/* promo banner */}
        <SubscribeNow
          hidden={subscription_status && subscription_status !== "free"}
        />
      </div>
      <Footer />
    </>
  );
}

export default MyQuestion;
