import React, { useEffect, useState } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import IntroImage from "./assets/whoarewe.jpg"
import { useDetectAdBlock } from "adblock-detect-react";
import AdBlockerModal from "./AdBlockerModal";
import { whoWeAreLang } from "../Global/globalFunction";

function WhoAreWe() {
  const [open,setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  const lang = localStorage.getItem('lang');
  useEffect(()=> {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  },[]);

  return (
    <>
        <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>{lang ? whoWeAreLang(lang,'who') : 'Who Are We'}</h1>
        <div className="d-flex whoArewe align-items-center">
         <img src={IntroImage} alt="Introduction" />
          <p>{lang ? whoWeAreLang(lang,'welcome') : 'Welcome to a realm where the labyrinth of legal complexities transforms into a well-lit path, and where the power of knowledge is at your fingertips. We are your trusted guide, your beacon in the legal landscape'}<br /><br />– {lang ? whoWeAreLang(lang,'we') : 'We are'}<b>Juradvice.</b></p>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default WhoAreWe;
