export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("lang");
  localStorage.removeItem("email");
};

export const navLang = (lang, navItem) => {
  if (lang === "en") {
    return navItem;
  } else if (lang === "fr") {
    switch (navItem) {
      case "Home":
        return "Home";
      case "Who Are We":
        return "Qui sommes-nous";
      case "Our Purpose":
        return "Notre mission";
      case "Submit Question":
        return "Soumettre une question";
      case "My Questions":
        return "Mes questions";
      case "My Profile":
        return "Mon profil";
      case "Login":
        return "Se connecter";
      case "Logout":
        return "Déconnexion";
      case "Privacy Policy":
        return "Confidentialité";
      case "Terms of Use":
        return "Conditions d'utilisation";
      case "Members Area":
        return "Espace membres";
      default:
        return navItem;
    }
  } else if (lang === "nl") {
    switch (navItem) {
      case "Home":
        return "Home";
      case "Who Are We":
        return "Wie zijn wij";
      case "Our Purpose":
        return "Onze missie";
      case "Submit Question":
        return "Vraag indienen";
      case "My Questions":
        return "Mijn vragen";
      case "My Profile":
        return "Mijn profiel";
      case "Login":
        return "Aanmelden";
      case "Logout":
        return "Uitloggen";
      case "Privacy Policy":
        return "Privacybeleid";
      case "Terms of Use":
        return "Gebruiksvoorwaarden";
      case "Members Area":
        return "Ledenpaneel";
      default:
        return navItem;
    }
  }
};

export const homeScreenLang = (lang, homeScreenItem) => {
  if (lang === "en") {
    return homeScreenItem;
  } else if (lang === "fr") {
    switch (homeScreenItem) {
      case "Juradvice for free legal advice and information":
        return "Juradvice pour des conseils et des informations juridiques gratuites";
      case "Didn't Find Question?":
        return "Vous n'avez pas trouvé de question ?";
      case "Submit Question":
        return "Soumettre une question";
      case "Search":
        return "Rechercher";
      case "No results found":
        return "Aucun résultat trouvé";
      default:
        return homeScreenItem;
    }
  } else if (lang === "nl") {
    switch (homeScreenItem) {
      case "Juradvice for free legal advice and information":
        return "Juradvice voor gratis juridisch advies en informatie";
      case "Didn't Find Question?":
        return "Vraag niet gevonden?";
      case "Submit Question":
        return "Vraag indienen";
      case "Search":
        return "Zoeken";
      case "No results found":
        return "Geen resultaten gevonden";
      default:
        return homeScreenItem;
    }
  }
};

export const submitQuestionLang = (lang, submitQuestionItem) => {
  if (lang === "en") {
    return submitQuestionItem;
  } else if (lang === "fr") {
    switch (submitQuestionItem) {
      case "Enter Question":
        return "Saisir une question";
      case "File Upload":
        return "Téléchargement de fichiers";
      case "Upload":
        return "Télécharger";
      case "Submit":
        return "Soumettre";
      case "Want to Priority Your Question & Remove Ads?":
        return "Vous souhaitez donner la priorité à votre question et supprimer les publicités ?";
      case "Subscribe now":
        return "Abonnez-vous maintenant";
      case "Submit Question":
        return "Soumettre une question";

      default:
        return submitQuestionItem;
    }
  } else if (lang === "nl") {
    switch (submitQuestionItem) {
      case "Enter Question":
        return "Vraag invoeren";
      case "File Upload":
        return "Bestand upload";
      case "Upload":
        return "Uploaden";
      case "Submit":
        return "Indienen";
      case "Want to Priority Your Question & Remove Ads?":
        return "Wilt u prioriteit geven aan uw vraag en advertenties verwijderen?";
      case "Subscribe now":
        return "Abonneer u nu";
      case "Submit Question":
        return "Vraag indienen";
      default:
        return submitQuestionItem;
    }
  }
};

export const myProfileLang = (lang, myProfileItem) => {
  if (lang === "en") {
    switch (myProfileItem) {
      case "Subscription Message":
        return "Oops! You don't have subscription. Your questions won't be prioritized.";
      case "Subscription MessageVIP":
        return "Great! You are already subscribed. Enjoy being prioritized.";
      case "Full Name":
        return "Username";
      default:
        return myProfileItem;
    }
  } else if (lang === "fr") {
    switch (myProfileItem) {
      case "Full Name":
        return "Nom d'utilisateur";
      case "Email":
        return "Email";
      case "Amount":
        return "Montant";
      case "Subscription Ends On":
        return "Date d'expiration de l'abonnement";
      case "Subscription Created at":
        return "Abonnement créé à";
      case "Subscription Status":
        return "État de l'abonnement";
      case "My Profile":
        return "Mon profil";
      case "Subscription":
        return "Abonnement";
      case "Free":
        return "Gratuit";
      case "Subscription Message":
        return "Oops ! Vous n'avez pas d'abonnement. Vos questions ne seront pas prioritaires.";
      case "Subscription MessageVIP":
        return "Génial ! Vous êtes déjà abonné. Profitez de la priorité.";
      default:
        return myProfileItem;
    }
  } else if (lang === "nl") {
    switch (myProfileItem) {
      case "Full Name":
        return "Gebruikersnaam";
      case "Email":
        return "Email";
      case "Amount":
        return "Hoeveelheid";
      case "Subscription Ends On":
        return "Vervaldatum abonnement";
      case "Subscription Status":
        return "Abonneerstatus";
      case "Subscription Created at":
        return "Abonnement aangemaakt op";
      case "My Profile":
        return "Mijn profiel";
      case "Subscription":
        return "Abonnement";
      case "Free":
        return "Gratis";
      case "Subscription Message":
        return "Oeps! Je hebt geen abonnement. Jouw vragen zullen geen prioriteit krijgen.";
      case "Subscription MessageVIP":
        return "Geweldig! Je bent al geabonneerd. Geniet van voorrang.";
      default:
        return myProfileItem;
    }
  }
};

export const myQuestionLang = (lang, questionItem) => {
  if (lang === "en") {
    return questionItem;
  } else if (lang === "fr") {
    switch (questionItem) {
      case "My Questions":
        return "Mes questions";
      case "Status":
        return "Statut";
      case "Submit":
        return "Soumettre";
      case "Subscription":
        return "Soumettre";
      case "Want to Priority Your Question & Remove Ads?":
        return "Vous souhaitez donner la priorité à votre question et supprimer les publicités ?";
      case "Subscribe now":
        return "Abonnez-vous maintenant";
      case "Message Reply":
        return "Pas encore de réponse";
      case "Files Uploaded":
        return "Fichiers téléchargés:";
      case "Answered":
        return "Répondu";
      case "Pending":
        return "En attente";
      case "No Question Asked From You":
        return "Pas de question posée de votre part";
      default:
        return questionItem;
    }
  } else if (lang === "nl") {
    switch (questionItem) {
      case "My Questions":
        return "Mijn vragen";
      case "Status":
        return "Toestand";
      case "Subscription":
        return "Abonnement";
      case "Submit":
        return "Indienen";
      case "Want to Priority Your Question & Remove Ads?":
        return "Wilt u prioriteit geven aan uw vraag en advertenties verwijderen?";
      case "Subscribe now":
        return "Abonner nu";
      case "Message Reply":
        return "Nog geen antwoord";
      case "Files Uploaded":
        return "Bestanden geüpload:";
      case "Answered":
        return "Beantwoord";
      case "Pending":
        return "In behandeling";
      case "No Question Asked From You":
        return "U heeft nog geen vraag gesteld";
      default:
        return questionItem;
    }
  }
};

export const loginLang = (lang, loginItem) => {
  if (lang === "en") {
    switch (loginItem) {
      case "Register Button":
        return "Register";
      case "Full Name":
        return "Username";
      case "failed":
        return "registration failed. There is already an account with this email.";
      case "Login":
        return "Login";
      default:
        return loginItem;
    }
  } else if (lang === "fr") {
    switch (loginItem) {
      case "Register":
        return "Créer un compte";
      case "Password":
        return "Mot de passe";
      case "Confirm Password":
        return "Confirmez le mot de passe";
      case "Full Name":
        return "Nom d'utilisateur";
      case "Forgot password":
        return "Mots de passe oublié";
      case "Login":
        return "Se connecter";
      case "Logout":
        return "Se déconnecter";
      case "Submit":
        return "se connecter";
      case "Confirm":
        return "Confirmer";
      case "Back":
        return "Retour";
      case "Register Button":
        return "Inscription";
      case "failed":
        return "L'inscription a échoué. Un compte existe déjà avec cette adresse e-mail.";
      default:
        return loginItem;
    }
  } else if (lang === "nl") {
    switch (loginItem) {
      case "Register":
        return "Registreer";
      case "Register Button":
        return "Registreer";
      case "Password":
        return "Wachtwoord";
      case "Confirm Password":
        return "Bevestig wachtwoord";
      case "Full Name":
        return "Gebruikersnaam";
      case "Forgot password":
        return "Wachtwoord vergeten";
      case "Submit":
        return "Inloggen";
      case "Login":
        return "Aanmelden";
      case "Logout":
        return "Uitloggen";
      case "Confirm":
        return "Confirmer";
      case "Back":
        return "Retour";
      case "failed":
        return "Registratie mislukt. Er bestaat al een account met dit e-mailadres.";
      default:
        return loginItem;
    }
  }
};

export const whoWeAreLang = (lang, item) => {
  if (lang === "en") {
    switch (item) {
      case "who":
        return "Who Are We";
      case "welcome":
        return "Welcome to a realm where the labyrinth of legal complexities transforms into a well-lit path, and where the power of knowledge is at your fingertips. We are your trusted guide, your beacon in the legal landscape.";
      case "we":
        return "We are";
      default:
        return item;
    }
  } else if (lang === "fr") {
    switch (item) {
      case "who":
        return "Qui sommes-nous";
      case "welcome":
        return "Bienvenue dans un royaume où le labyrinthe des complexités légales se transforme en un chemin bien éclairé, et où la puissance de la connaissance est à portée de main. Nous sommes votre guide de confiance, votre phare dans le paysage juridique.";
      case "we":
        return "Nous sommes";

      default:
        return item;
    }
  } else if (lang === "nl") {
    switch (item) {
      case "who":
        return "Wie zijn wij";
      case "welcome":
        return "Welkom in een rijk waar het labyrint van juridische complexiteiten verandert in een goed verlicht pad, en waar de kracht van kennis binnen handbereik is. Wij zijn uw vertrouwde gids, uw baken in het juridische landschap.";
      case "we":
        return "Wij zijn";
      default:
        return item;
    }
  }
};
