import React from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";

function PrivacyPolicyFr() {
  return (
    <>
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Déclaration de confidentialité et de cookies</h1>
        <p>
          Cette "Politique de confidentialité" régit le traitement des données
          personnelles par le responsable du traitement : Juradvice (ci-après
          dénommé "Responsable", "Nous" ou "Juradvice"). Veuillez lire
          attentivement cette Politique de confidentialité car elle contient des
          informations essentielles sur le traitement de vos données
          personnelles et sur l'utilisation des cookies. En fournissant vos
          données personnelles sur le site Web et en utilisant les adresses
          e-mail mentionnées sur ce site Web pour fournir des données
          personnelles, vous déclarez avoir pris connaissance de cette Politique
          de confidentialité et acceptez expressément le traitement lui-même.
        </p>

        <h2>Article 1 – Généralités</h2>
        <p>
          Juradvice suit la "Loi du 8 décembre 1992 relative à la protection de
          la vie privée à l'égard du traitement des données à caractère
          personnel", telle que modifiée par la loi du 11 décembre 1998, et les
          dispositions anti-spam du Livre XII du Code de droit économique
          concernant le "droit de l'économie électronique". Juradvice se
          conforme également au Règlement européen 2016/679 du 27 avril 2016
          relatif à la protection des données à caractère personnel. Le
          responsable du traitement de vos données personnelles est Juradvice,
          avec une déclaration auprès de la Commission de la protection de la
          vie privée.
        </p>

        <h2>Article 2 – Données personnelles</h2>
        <p>
          2.1. Données personnelles que vous nous communiquez : Votre adresse
          IP, comportement de navigation, origine, termes de recherche, nom de
          profil, mot de passe, adresse e-mail, données de connexion, image de
          profil, coordonnées, données personnelles, et certaines données sont
          collectées via les cookies (voir l'article 9 pour la politique en
          matière de cookies).
        </p>

        <p>
          2.2. Mode de collecte des données : Cela inclut l'utilisation de
          cookies, l'inscription ou l'utilisation de la plateforme, la demande
          d'informations supplémentaires après un contact avec Juradvice, le
          remplissage de formulaires pour la discussion de problèmes, et le
          partage de données avec d'autres utilisateurs sur nos sites.
        </p>

        <p>
          2.3. Cookies : Le site Web utilise des cookies pour la collecte de
          données. Voir l'article 9 pour la politique en matière de cookies.
        </p>

        <h2>Article 3 – Objectifs du traitement</h2>
        <p>
          3.1. Objectifs généraux : Juradvice utilisera exclusivement les
          données personnelles pour améliorer le forum, gérer votre compte, le
          marketing direct, répondre aux questions et vérifier les données. Voir
          l'article 9 pour la politique en matière de cookies.
        </p>

        <p>
          3.2. Marketing direct : Les données sont utilisées à des fins de
          marketing direct avec votre consentement explicite ("opt-in"). Vous
          pouvez retirer ce consentement à tout moment.
        </p>

        <p>
          3.3. Transmission à des tiers : En cas de réorganisation ou de
          transfert d'activités de Juradvice, des données peuvent être
          transférées à de nouvelles entités ou à des tiers. Juradvice ne vend,
          ne loue ni ne partage vos données personnelles à des fins commerciales
          sans votre consentement.
        </p>

        <p>
          3.4. Exigences légales : Dans des cas exceptionnels, Juradvice peut
          divulguer des données personnelles pour se conformer à des ordonnances
          judiciaires ou à des lois ou règlements contraignants.
        </p>

        <h2>Article 4 – Durée du traitement</h2>
        <p>
          Les données personnelles sont conservées et traitées aussi longtemps
          que nécessaire pour les objectifs du traitement et la relation
          contractuelle entre Juradvice et vous.
        </p>

        <h2>Article 5 – Vos droits</h2>
        <p>
          5.1. Droit d'accès et de consultation : Vous avez le droit de prendre
          connaissance gratuitement de vos données personnelles et de leur
          utilisation par Juradvice.
        </p>

        <p>
          5.2. Droit de rectification, de suppression et de limitation : Vous
          avez le droit de faire rectifier, compléter ou supprimer des données
          personnelles, et de demander la limitation de leur traitement.
        </p>

        <p>
          5.3. Droit d'opposition : Vous avez le droit de vous opposer au
          traitement de vos données personnelles et à leur utilisation à des
          fins de marketing direct sans indication de motifs.
        </p>

        <p>
          5.4. Droit de révocation du consentement : Pour le traitement basé sur
          le consentement, vous pouvez révoquer le consentement à tout moment.
        </p>

        <p>
          5.5. Exercice de vos droits : Les droits peuvent être exercés en
          contactant info@Juradvice.be.
        </p>

        <p>
          5.6. Plus d'informations : Pour plus d'informations sur les droits en
          matière de confidentialité et le traitement des données personnelles,
          veuillez contacter la Commission de la protection de la vie privée
          belge.
        </p>

        <h2>Article 6 – Sécurité et confidentialité</h2>
        <p>
          6.1. Juradvice a mis en place des mesures de sécurité pour protéger
          les données personnelles contre la destruction, la perte, la
          falsification, la modification, l'accès non autorisé ou la divulgation
          à des tiers.
        </p>

        <p>
          6.2. Juradvice n'est pas responsable des dommages résultant d'une
          utilisation incorrecte ou illicite de données personnelles par des
          tiers.
        </p>

        <p>
          6.3. Vous devez respecter les consignes de sécurité, telles que la
          prévention de l'accès non autorisé à votre connexion et à votre code.
          Vous êtes responsable de l'utilisation du site Web à partir de votre
          ordinateur, de votre adresse IP et de vos données d'identification,
          ainsi que de leur confidentialité.
        </p>

        <h2>Article 7 – Accès par des tiers</h2>
        <p>
          Juradvice accorde l'accès aux données personnelles aux employés, avec
          des garanties de niveau de protection conformément à cette Politique
          de confidentialité.
        </p>

        <h2>Article 8 – Cookies</h2>
        <p>
          8.1. Qu'est-ce que les cookies : Un "cookie" est un petit fichier
          envoyé par le serveur de Juradvice et placé sur le disque dur de votre
          ordinateur. Les informations sur ces cookies sont lisibles lors de la
          visite du site Web.
        </p>

        <p>
          8.2. Pourquoi utilisons-nous des cookies : Les cookies et les
          technologies similaires distinguent les préférences d'utilisation,
          améliorent l'expérience utilisateur et optimisent le site Web. Le
          consentement est demandé lors de la première visite, et les paramètres
          des cookies peuvent être modifiés.
        </p>

        <p>
          8.3. Types de cookies : Les cookies fonctionnels ou nécessaires sont
          utilisés sans consentement préalable. D'autres cookies, tels que les
          cookies de performance et de publicité, nécessitent votre
          consentement. Juradvice se réserve le droit de placer un script pour
          détecter les bloqueurs de publicités.
        </p>

        <h2>Responsable de la gestion de ce site Web :</h2>
        <p>
          Juradvice
          <br />
          E-mail :{" "}
          <a href="mailto:info@juradvice.be">
            <u>info@juradvice.be</u>
          </a>
        </p>
      </div>

      <Footer />
    </>
  );
}
export default PrivacyPolicyFr;
