import "./session.css";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import QuizIcon from "@mui/icons-material/Quiz";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { Box, styled } from "@mui/system";
import TopNav from "./TopNav";
import { Modal, TextField, Typography } from "@mui/material";
import file from "./file.svg";
import Footer from "./Footer";
import GlobalAlert from "../Global/GlobalAlert";
import axios from "axios";
import { submitQuestionLang } from "../Global/globalFunction";
import CloseIcon from "@mui/icons-material/Close";
import { useDetectAdBlock } from "adblock-detect-react";
import AdBlockerModal from "./AdBlockerModal";
import SubscribeNow from "./SubscribeNow";
import { color } from "echarts";
const theme = createTheme({
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "10px", // Set your desired border radius here
          backgroundColor: "white",
        },
        input: {
          borderRadius: "10px !important",
        },
      },
    },
  },
});

function SubmitQuestion(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [active, setActive] = useState(null);
  const [formData, setFormData] = useState({
    message: "",
    files: [],
  });
  const [alertMessages, setAlertMessages] = useState({
    message: "",
    status: "",
    flag: "",
  });
  const [adModal, setAdModal] = useState(false);
  const [modalCloseBtn, setModalCloseBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");
  const lang = localStorage.getItem("lang");
  const subscription = localStorage.getItem("subscription");
  const adBlockDetected = useDetectAdBlock();
  let className1;

  const handleDeleteFile = (index) => {
    const newFiles = [...formData.files];
    newFiles.splice(index, 1);
    setFormData({ files: newFiles });
  };

  if (windowWidth <= 400) {
    className1 = "inboxmobilestyle";
  } else if (windowWidth < 900) {
    className1 = "inboxtabletstyle";
  } else {
    className1 = "inboxstyle";
  }
  const responsiveStyle1 =
    windowWidth <= 400
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "5%",
          top: "90px",
        }
      : windowWidth <= 900
      ? {
          borderRadius: "10px",
          padding: "0px",
          position: "absolute",
          left: "25%",
          top: "10px",
        }
      : {
          borderRadius: "10px",
          padding: "0px",
          top: "100px",
        };
  const navigate = useNavigate();

  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const handleclick = () => {
    if (formData.message.trim().length < 1) {
      setAlertMessages({
        message: "Please Enter Your Message",
        status: "error",
        flag: true,
      });
      return;
    }
    if (subscription.trim() !== "free") {
      sendMessage();
    } else {
      showAd();
    }
  };

  const showAd = () => {
    setAdModal(true);
    setTimeout(() => setModalCloseBtn(true), 7000);
  };

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  /* On Change */
  const onChange = (event) => {
    const { name, type, value, files } = event.target;
  
    if (type === "file") {
      // Check if the number of files exceeds the limit
      if (formData.files.length >= 2) {
        setAlertMessages({
          message: "You cannot upload more than 2 files",
          status: "error",
          flag: true,
        });
        return;
      }
  
      // Handle the file selection logic
      const selectedFiles = Array.from(files);
      setFormData((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...selectedFiles],
      }));
    } else {
      // Handle other input types if needed
      setFormData((prevState) => ({
        ...prevState,
        message: value,
      }));
    }
  };

  console.log("formData", formData);

  const sendMessage = () => {
    const requestData = new FormData();
    if (formData.files.length > 0) {
      for (let i = 0; i < formData.files.length; i++) {
        requestData.append("file[]", formData.files[i]);
      }
    } else {
      requestData.append("file[]", "");
    }
    requestData.append("sender_id", userEmail);
    requestData.append("receiver_id", "info@juradvice.be");
    requestData.append("message", formData.message);
    axios
      .post("https://juradvice.be/api/message/send.php", requestData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (response.data.status !== "error") {
            console.log("response", response.data);
            setAlertMessages({
              message: "Question send Successfully",
              status: "success",
              flag: true,
            });
            setFormData({
              message: "",
              files: [],
            });
          } else {
            setAlertMessages({
              message: response.data.message,
              status: "error",
              flag: true,
            });
          }
          setModalCloseBtn(false);
          setAdModal(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setAlertMessages({
          message: "Something went Wrong",
          status: "error",
          flag: true,
        });
      });
  };
  const handleClose = () => {
    sendMessage();
  };
  const headerstyle = {
    color: "#ffffff",
    background: "#224589",
    height: "50px",
  };

  return (
    <>
      <AdBlockerModal open={open} />
      <Modal
        open={adModal}
        onClose={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box style={responsiveStyle1} className={className1}>
          <Typography
            style={{
              borderTopLeftRadius: "10px",
              borderTopRightRadius: "10px",
              paddingLeft: "8px",
              paddingTop: "7px",
            }}
            sx={headerstyle}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            <div className="m-3 my-1">
              <h4>Ad</h4>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginTop: "-30px",
              }}
            >
              {modalCloseBtn ? (
                <IconButton onClick={() => handleClose()} aria-label="Close">
                  <CloseIcon
                    style={{ marginTop: "-10px", color: "#ffffff" }}
                    className="mx-2"
                  />
                </IconButton>
              ) : (
                <></>
              )}
            </div>
          </Typography>
          <div className="p-5 adBox">
            <p className="text-center">
              Wait for some seconds to get the close button, By clicking on
              close button will <b>submit your question</b>. To get rid of ads{" "}
              <b>get subscription</b>.
            </p>
            <img
              alt="ads"
              src="https://images.unsplash.com/photo-1575936123452-b67c3203c357?q=80&w=1000&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Mnx8aW1hZ2V8ZW58MHx8MHx8fDA%3D"
            />
          </div>
        </Box>
      </Modal>

      <TopNav disableBackground={true} />
      <div className="container submitQuestion">
        <div>
          <GlobalAlert
            setAlertMessages={setAlertMessages}
            alertMessages={alertMessages}
          />
          <div className="mt-3 card-body mb-3">
            <ThemeProvider theme={theme}>
              <h5>
                {lang
                  ? submitQuestionLang(lang, "Enter Question")
                  : "Enter Question"}
              </h5>
              <TextField
                fullWidth
                size="small"
                id="outlined-multiline-static"
                multiline
                rows={4}
                defaultValue="Write Your Questions Here"
                onChange={onChange}
                value={formData.message}
              />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <h5 className="mt-3">
                {lang ? submitQuestionLang(lang, "File Upload") : "File Upload"}
              </h5>
              <div class="upload-btn-wrapper card">
                {/* <button class="btnfileUploader">
                        <img src={file} className="mx-2" alt="File Icon" />
                        Click to Upload
                      </button> */}
                <button className="d-flex align-items-center justify-content-center">
                  <img
                    width="35"
                    height="35"
                    src="https://img.icons8.com/ios/50/upload--v1.png"
                    alt="upload--v1"
                  />
                  &nbsp;&nbsp;{" "}
                  {lang
                    ? submitQuestionLang(
                        lang,
                        "Upload File (Max limit 2 files)"
                      )
                    : "Upload File"}
                </button>
                <input type="file" multiple name="myfile" onChange={onChange} />
              </div>
            </ThemeProvider>
            {formData.files.length > 0 && (
              <div className="filesNames">
                <p>Selected Files:</p>
                <ul className="d-flex flex-column" style={{"gap": "10px"}}>
                  {formData.files.map((file, index) => (
                    <li key={index}>
                      {file.name}
                      <span
                        className="deleteFile ps-2"
                        style={{"color": "red", "cursor": "pointer"}}
                        onClick={() => handleDeleteFile(index)}
                      >
                        &#10006;
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <div className="w-100 d-flex justify-content-center">
              <button onClick={handleclick} className="loginRegBtn mt-3 w-100">
                {lang ? submitQuestionLang(lang, "Submit") : "Submit"}{" "}
              </button>
            </div>
          </div>
        </div>
        {/* promo banner */}
        <SubscribeNow hidden={subscription && subscription !== "free"} />
      </div>
      <Footer />
    </>
  );
}

export default SubmitQuestion;
