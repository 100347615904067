
import PersonIcon from '@mui/icons-material/Person';
import HelpIcon from '@mui/icons-material/Help';
import EnhancedEncryptionIcon from '@mui/icons-material/EnhancedEncryption';
import LogoutIcon from '@mui/icons-material/Logout';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export function Users() {
 
  return { name: 'Users', path: '/users',  icon: <PersonIcon style={{marginTop:"-5px"}}/>, }
}
export function Inbox() {
  return { name: 'Inbox', path: '/inbox', icon: <ForwardToInboxIcon fontSize='small' style={{marginTop:"-5px"}}/> }
}
export function Question() {
  return { name: 'Questions', path: '/questions', icon: <HelpIcon fontSize='small' style={{marginTop:"-7px"}}/> }
}
export function Password() {
  return { name: 'Change Password', path: '/admin-change-password',  icon: <EnhancedEncryptionIcon  fontSize='small' style={{marginTop:"-5px"}}/>  }
}
export function Logout() {
  return { name: 'Logout', path: '/login',  icon: <LogoutIcon fontSize='small' style={{marginTop:"-5px",flexDirection:"column"}}/> }
}

export function Dashboard() {
  return { name: 'Dashboard', path: '/users', icon: 'dashboard' }
}


export const navigations = [
  
  // Dashboard(),
  // { label: 'Menu', type: 'label' },
  Users(),
  Inbox(),
  Question(),
  Password(),
  Logout(),
]

