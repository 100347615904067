import React, { useState, useEffect } from "react";
import TopNav from "./TopNav";
import Footer from "./Footer";
import AdBlockerModal from "./AdBlockerModal";
import { useDetectAdBlock } from "adblock-detect-react";

function TermsOfUseNl() {
  const [open, setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  return (
    <>
      <AdBlockerModal open={open} />
      <TopNav disableBackground={true} />
      <div className="policyContent container">
        <h1>Gebruiksvoorwaarden</h1>

        <h2>1. Algemene Disclaimer</h2>
        <p>
          De informatie op deze website is uitsluitend bedoeld voor algemene
          informatieve doeleinden en mag niet worden beschouwd als juridisch
          advies. Deze website vervangt geen professioneel juridisch advies.
          Raadpleeg altijd een erkende juridische professional voor specifieke
          juridische kwesties. De informatie op deze website kan verouderd of
          onvolledig zijn, en de nauwkeurigheid ervan kan niet worden
          gegarandeerd. De eigenaar en beheerders van deze website zijn niet
          aansprakelijk voor eventuele schade als gevolg van het gebruik van de
          verstrekte informatie.
        </p>

        <h2>2. Beperking van Aansprakelijkheid</h2>
        <p>
          Noch de eigenaar van de website noch het personeel kunnen
          aansprakelijk worden gesteld voor eventuele schade of verliezen als
          gevolg van het gebruik van informatie op deze website. De verstrekte
          informatie en adviezen zijn gebaseerd op algemene principes en zijn
          mogelijk niet specifiek voor uw situatie.
        </p>

        <h2>3. Onafhankelijkheid van Advies</h2>
        <p>
          De meningen en adviezen die op deze website worden gegeven,
          vertegenwoordigen niet noodzakelijk de standpunten van de eigenaar of
          beheerders. De website kan advies bevatten van verschillende bronnen,
          waaronder bijdragen van gebruikers, maar in dit geval zijn er geen
          bijdragen van gebruikers.
        </p>

        <h2>4. Vertrouwelijkheid en Privacy</h2>
        <p>
          Wij nemen uw privacy serieus en streven ernaar om alle persoonlijke
          informatie die u met ons deelt, te beschermen. Wij raden aan onze
          privacyverklaring te bekijken om te begrijpen hoe wij uw gegevens
          behandelen.
        </p>

        <h2>5. Intellectueel Eigendom</h2>
        <p>
          Alle inhoud op deze website is beschermd door auteursrechten en andere
          intellectuele eigendomsrechten. Het kopiëren, verspreiden of gebruiken
          van inhoud van deze website zonder toestemming is verboden.
        </p>

        <h2>6. Wijzigingen in Gebruiksvoorwaarden</h2>
        <p>
          De eigenaar behoudt zich het recht voor om deze gebruiksvoorwaarden op
          elk moment en zonder voorafgaande kennisgeving te wijzigen. Het is de
          verantwoordelijkheid van gebruikers om regelmatig te controleren op
          updates van deze voorwaarden.
        </p>

        <h2>7. Laatste Bepalingen</h2>
        <p>
          Deze gebruiksvoorwaarden vormen de volledige overeenkomst tussen u en
          de eigenaar met betrekking tot uw gebruik van deze website. Eventuele
          wijzigingen of toevoegingen aan deze voorwaarden moeten schriftelijk
          worden overeengekomen.
        </p>

        <p>
          Door deze website te gebruiken voor juridisch advies, stemt u in met
          deze gebruiksvoorwaarden. Als u het niet eens bent met een van deze
          voorwaarden, stop dan uw gebruik van de website.
        </p>
      </div>

      <Footer />
    </>
  );
}
export default TermsOfUseNl;
