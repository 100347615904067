import dashboardRoutes from 'app/views/dashboard/DashboardRoutes';
import BasePage from './views/BasePage';
import sessionRoutes from 'app/views/sessions/SessionRoutes';
import { Navigate } from 'react-router-dom';
import MatxLayout from './components/MatxLayout/MatxLayout';


const routes = [
  {
    element: (
        <MatxLayout />
    ),
    children: [...dashboardRoutes, ...BasePage],
  },
  ...sessionRoutes,
  { path: '/', element: <Navigate to="dashboard/dashboard" /> },
];

export default routes;
