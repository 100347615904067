import React, { useEffect, useState } from "react";
import MissionImg from "./assets/mission.png";
import PurposeImg from "./assets/purpose.jpg";
import Icon1 from "./assets/icon1.png";
import Icon2 from "./assets/icon2.png";
import Icon3 from "./assets/icon3.png";
import { useDetectAdBlock } from "adblock-detect-react";

const OurPurposeFr = () => {
  const [open, setOpen] = useState(false);
  const adBlockDetected = useDetectAdBlock();
  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);
  return (
    <div className="policyContent container">
      <h1>Notre mission</h1>
      <div className="d-flex whoArewe align-items-center">
        <img src={PurposeImg} alt="Panneau de loi" />
        <p>
          Chez Juradvice, nous croyons que l'accès à des conseils juridiques et
          à des informations n'est pas un privilège, mais un droit fondamental.
          Nous comprenons que naviguer dans les complexités du monde juridique
          peut être intimidant et écrasant. C'est là que nous intervenons.
        </p>
      </div>
      <div className="d-flex whoArewe whoArewe2 align-items-center mt-4">
        <p>
          Notre mission est simple, mais profonde : nous sommes là pour vous
          donner les connaissances et les ressources dont vous avez besoin pour
          prendre des décisions éclairées, comprendre vos droits et faire face
          avec confiance aux défis juridiques que la vie peut vous réserver.
        </p>
        <img src={MissionImg} alt="Notre Mission" />
      </div>
      <div className="heartofcomitment text-center">
        <h2>Au Cœur de Notre Engagement</h2>
        <div className="d-flex justify-content-between">
          <div className="iconBox">
            <img src={Icon1} alt="Accessibilité" />
            <h3>Accessibilité</h3>
            <p>
              Nous avons construit un pont au-dessus du fossé entre les
              complexités du droit et votre compréhension. Nous nous engageons à
              rendre les conseils juridiques et les informations accessibles à
              tous, indépendamment de leur origine, de leurs ressources ou de
              leur expérience.
            </p>
          </div>
          <div className="iconBox">
            <img src={Icon2} alt="Expertise" />
            <h3>Expertise</h3>
            <p>
              Notre équipe est composée d'experts juridiques passionnés par la
              simplification du droit pour vous. Ils apportent une richesse
              d'expérience et de connaissances, prêts à répondre à vos questions
              et à fournir des informations qui peuvent vous guider à travers
              des questions juridiques complexes.
            </p>
          </div>
          <div className="iconBox">
            <img src={Icon3} alt="Autonomisation" />
            <h3>Autonomisation</h3>
            <p>
              Nous croyons fermement que la connaissance est le pouvoir. En vous
              armant des connaissances juridiques dont vous avez besoin, nous
              visons à vous autonomiser pour prendre des décisions éclairées,
              protéger vos droits et surmonter les obstacles juridiques avec
              confiance.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurPurposeFr;
