import {
  Avatar,
  Hidden,
  Icon,
  IconButton,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { Box, styled, useTheme } from "@mui/system";
import { MatxMenu, MatxSearchBox } from "app/components";
import { themeShadows } from "app/components/MatxTheme/themeColors";
import useSettings from "app/hooks/useSettings";
import { topBarHeight } from "app/utils/constant";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Span } from "../../../components/Typography";
import { logout } from "app/views/Global/globalFunction";
import { Button } from "bootstrap";

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const TopbarRoot = styled("div")(({ theme }) => ({
  top: 0,
  zIndex: 96,
  transition: "all 0.3s ease",
  boxShadow: themeShadows[8],
  height: topBarHeight,
}));

const TopbarContainer = styled(Box)(({ theme }) => ({
  padding: "8px",
  paddingLeft: 18,
  paddingRight: 20,
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  background: theme.palette.primary.main,
  [theme.breakpoints.down("sm")]: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  [theme.breakpoints.down("xs")]: {
    paddingLeft: 14,
    paddingRight: 16,
  },
}));

const UserMenu = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  borderRadius: 24,
  padding: 4,
  "& span": { margin: "0 8px" },
}));

const StyledItem = styled(MenuItem)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  minWidth: 185,
  "& a": {
    width: "100%",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
  },
  "& span": { marginRight: "10px", color: theme.palette.text.primary },
}));

const Layout1Topbar = () => {
  const theme = useTheme();
  const { settings, updateSettings } = useSettings();
  const isMdScreen = useMediaQuery(theme.breakpoints.down("md"));
  let user = JSON.parse(localStorage.getItem("userData"));
  const navigate =  useNavigate();
  const updateSidebarMode = (sidebarSettings) => {
    updateSettings({
      layout1Settings: { leftSidebar: { ...sidebarSettings } },
    });
  };

  const handleSidebarToggle = () => {
    let { layout1Settings } = settings;
    let mode;
    if (isMdScreen) {
      mode = layout1Settings.leftSidebar.mode === "close" ? "mobile" : "close";
    } else {
      mode = layout1Settings.leftSidebar.mode === "full" ? "close" : "full";
    }
    updateSidebarMode({ mode });
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  // style={
  //   window.innerWidth <= 500
  //     ? { justifyContent: "center" }
  //     : { marginTop: "60px", justifyContent: "center" }
  // }
  // className={
  //   window.innerWidth < 500
  //     ? "mobilemainHeading"
  //     :
  //     "mainHeading"
  // }

  const returnHome = () => {
    logout();
    navigate('/login');
  }

  return (
    <TopbarRoot>
      <TopbarContainer>
        <Box
          style={
            window.innerWidth < 900
              ? 
              {}
              :
              {display: "none"}
          }
          display="flex"
        >
          <StyledIconButton onClick={handleSidebarToggle}>
            <Icon>menu</Icon>
          </StyledIconButton>
        </Box>
        <Box display="flex">
          <MatxMenu
            menuButton={
              <UserMenu>
                <Hidden xsDown>
                  <Span>
                    <strong>Hello,</strong> Admin
                  </Span>
                </Hidden>
              </UserMenu>
            }
          ></MatxMenu>
        </Box>
        <div>
                
                  <button className="adminLogout"  onClick={returnHome}>
                  Logout
                  </button>
                
        </div>
      </TopbarContainer>
    </TopbarRoot>
  );
};

export default React.memo(Layout1Topbar);
