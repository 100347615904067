import React, { useEffect, useState } from "react";
import "./session.css";
import bluelogo from "./bluelogo.svg";
import { Link } from "react-router-dom";
import { navLang } from "../Global/globalFunction";

const Footer = (props) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const lang = localStorage.getItem("lang");
  const token = localStorage.getItem("token");
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer>
      <div
        className={
          windowWidth <= 400
            ? "row justify-content-center  flex-column"
            : "row justify-content-center "
        }
      >
        <div
          className={
            windowWidth <= 400
              ? "d-flex justify-content-center align-items-center flex-column"
              : "d-flex flex-column text-center align-items-center col-lg-3 col-8 col-md-7 col-sm-7 p-3"
          }
        >
          <img className="footerLogo" src={bluelogo} alt="Blue Logo" />
          <div className="footerLinks">
            <Link to={"/privacy-policy"}>
              {lang ? navLang(lang, "Privacy Policy") : "Privacy Policy"}
            </Link>
            |
            <Link to={"/terms-of-use"}>
              {lang ? navLang(lang, "Terms of Use") : "Terms of Use"}
            </Link>
          </div>
          <p>&copy; {currentYear} | Juradvice</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
