import "./session.css";
import { useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormControl from '@mui/material/FormControl';
import { useNavigate } from "react-router-dom";
import {  Modal } from "@mui/material";
import { Box, styled } from '@mui/system';
import TopNav from "./TopNav";
import Footer from "./Footer";
import axios from "axios";
import _ from "lodash";
import { homeScreenLang } from "../Global/globalFunction";


const AccordionItem = (props) => {
    const contentEl = useRef();
    const { handleToggle, active, faq } = props;
    const { question, id, answer,description } = faq;
    console.log("Faq",faq);
    const questionClick = (data) => {
      if(!props.noResult)
      {
     handleToggle(data)
      }
    }
    return (
      <div className="rc-accordion-card">
        <div className="">
          <div className={'rc-accordion-toggle p-3 active accordionText'} onClick={() => questionClick(faq)}>
            <div className="d-flex" style={{ gap: "15px" }}> 
          
              <div className="mt-1" dangerouslySetInnerHTML={{__html:description}} />
            </div>
        
          </div>
        </div>
        <div ref={contentEl} className={'rc-collapse d-flex show mb-3'} style={ { height: "auto" }
        }>
        </div>
      </div>
    )
  }


  const theme = createTheme({
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            borderRadius: '10px', // Set your desired border radius here
            backgroundColor: "white"
          },
          input: {
            borderRadius: "10px !important",
            height: "48px",
          }
        },
      },
    },
  });
const SearchBox = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [active, setActive] = useState(null);
    const [faqs,setFaqs] = useState([]);
    const token = localStorage.getItem('token');
  


    const removeSpecialCharsAndReplaceSpace = (text) => {
      // Regular expression to match special characters (excluding alphanumeric characters and hyphens)
      const regex = /[^a-zA-Z0-9\-_]/g;
      const removeSpace = text.replace(/\s/g, '-');
      return removeSpace.replace(regex, '')
    }
    const handleToggle = (data) => {
  
      const text = removeSpecialCharsAndReplaceSpace(data.question)
      console.log("Text",text);
      navigate(`/question/${data.id}/${text}`,{
        state:{
          'id':data.id,
          'answer':data.answer,
          'question':data.question
        }
      });
      // Clear the input value
    setSearchValue("");
    setOpenAccordion(false);
    };
    const navigate = useNavigate();
  
    const handleclick = () => {
      if(token)
      {
      navigate("/submit-question"); // Navigate to the desired route upon login
      }
      else {
        navigate('/login');
      }
    };
    const [searchValue, setSearchValue] = useState('')
    const [openAccordion, setOpenAccordion] = useState(false)
    const [open,setOpen] = useState(false);
    const lang = localStorage.getItem('lang');
    const onAccordionChange = (e) => {
      const value = e.target.value
      setSearchValue(e.target.value)
      if (value.length > 0) {
        setOpenAccordion(true);
        debounceQuestion(value);
  
      } else {
        setOpenAccordion(false)
      }
    }
    useEffect(() => {
  
      if (window.canRunAds) {
        setOpen(true);
      } else {
        setOpen(false);
      }
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);
  
    
    
    const getSearchQuestions = (term) => {
      let requestData = {
        lang:lang ? lang : "en",
        word:term
      }
  
      axios.post('https://juradvice.be/api/user/search_questions.php',requestData).then((response) => {
        if(response.status === 200)
        {
          const words = term.split(/\s+/).map(word => `\\b${word}\\b`).join('|');
        const regex = new RegExp(words, 'gi');
          
          console.log("Regex",regex);
          const data = response.data.data.map((val) => {
            return {
              ...val,
              description:val.question.replace(regex,`<span class="searchTerm">$&</span>`),
            }
          })
          console.log("Dataaa",data);
          setFaqs([...data]);
        } 
      }).catch((error) => {
        console.log('error',error);
        setFaqs([]);
      })
  
    }
  
  const debounceQuestion = _.debounce(getSearchQuestions,500);
    
  

return (
    <>
     <div className="container-fluid">
          <div className="row justify-content-center position-relative z-1">
            <div className="d-flex flex-column align-items-center pageHeader" style={{ backgroundColor: "#26478E" }}>
              <div className="container">
              <div
              >
                <h1 className="text-white pb-2 pt-5 text-center" >
                  {lang ? homeScreenLang(lang,'Juradvice for free legal advice and information') : "Juradvice for free legal advice and information"}
                  
                  </h1>
              </div>
              <div className="pb-5">
                <ThemeProvider theme={theme}>
                  <FormControl variant="outlined" size="small" fullWidth>
                    <OutlinedInput
                      placeholder={lang ? homeScreenLang(lang,'Search') : 'Search'}
                      value={searchValue}
                      onChange={(e) => onAccordionChange(e)}
                      startAdornment={
                        <InputAdornment position="start">
                          <IconButton
                          style={{pointerEvents:"none"}}
                            aria-label="toggle password visibility"
                            edge="end"
                          >
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                </ThemeProvider>
              </div>
              </div>
            </div>
          </div>
        </div>
        {openAccordion === true ? <div className="row d-flex justify-content-center searchResultContainer">
          <div className="container">
            <div className="card">
              <div className="card-body d-flex flex-column align-items-center pb-2 searchResultOuter">

              
                  {faqs && faqs.length > 0 ? ( faqs.map((faq, index) => {
                    return (
                      <div className="accordinMainDiv">
                      <AccordionItem key={index} active={active} handleToggle={handleToggle} noResult={false} faq={faq} className="searchItem" />
                      </div>
                    )
                  })
                ) : <>
                 <div className="accordinMainDiv">
                      <AccordionItem key={0} active={active} handleToggle={handleToggle} noResult={true} faq={{description:lang ? homeScreenLang(lang,'No results found') : 'No results found'}} className="searchItem" />
                      </div>
                      </>
                  }
                {/* </div> */}
              </div>
            </div>
          </div>
        </div> : <></>
        }
    </>
)


}

export default SearchBox